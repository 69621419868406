import React, { useState } from 'react';
import { Layout, ShoppingCartDrawer } from '../components';
import {
    Divider,
    Heading,
    useDisclosure,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    SimpleGrid,
    Box,
    Image,
    Text,
    Flex,
    Select,
    Badge,
} from '@chakra-ui/react';
import { Redirect, useParams } from 'react-router-dom';
import { useGetOrderById, useUpdateOrder } from '../orders';
import { ShoppingCartDto, Status } from '../common/dto';
import { useQueryClient } from 'react-query';
import { FIRESTORE_PATH } from '../common/enums';
import { routes, useAuth } from '../common';
import { format } from 'date-fns';

type OrderDetailParams = {
    id: string;
};

export const OrderDetailPage: React.FC = () => {
    const { onClose, onOpen, isOpen } = useDisclosure();
    const { id } = useParams<OrderDetailParams>();
    const queryClient = useQueryClient();
    const { mutateAsync: updateOrder } = useUpdateOrder();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { authenticatedUser } = useAuth();

    const { data: order, isLoading: isFetching } = useGetOrderById(id);

    const handleUpdateStatus = async (
        e: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        if (!order) return;

        try {
            const newStatus = e.currentTarget.value;
            updateOrder(
                {
                    id: order.id,
                    data: {
                        ...order,
                        status: newStatus as Status,
                    },
                },
                {
                    onSuccess: () =>
                        queryClient.invalidateQueries(FIRESTORE_PATH.ORDERS),
                },
            );
        } catch (error) {
            console.error('error:', error);
        }
    };

    if (order && authenticatedUser && !isFetching) {
        if (
            authenticatedUser.userId !== order.userId &&
            !authenticatedUser.isAdmin
        ) {
            return <Redirect to={routes.designer} />;
        }
    } else if (!order && !isFetching) {
        return <Redirect to={routes.designer} />;
    }

    return (
        <Layout isLoading={isLoading || isFetching} onToggleDrawer={onOpen}>
            <ShoppingCartDrawer
                isOpen={isOpen}
                onChangeLoadingState={setIsLoading}
                onOpen={onOpen}
                onClose={onClose}
            />
            <Flex justifyContent="space-between">
                <Box>
                    <Heading as="h1">Order Details</Heading>
                    <Divider variant="vPlexDivider" />
                </Box>
                {authenticatedUser?.isAdmin && order && (
                    <Box>
                        <Heading as="h2" size="xs" marginBottom="5px">
                            Bestelstatus
                        </Heading>
                        <Select
                            width="200px"
                            defaultValue={order.status}
                            onChange={handleUpdateStatus}
                        >
                            <option value={Status.pending}>
                                {Status.pending}
                            </option>
                            <option value={Status.processing}>
                                {Status.processing}
                            </option>
                            <option value={Status.ready}>{Status.ready}</option>
                            <option value={Status.completed}>
                                {Status.completed}
                            </option>
                        </Select>
                    </Box>
                )}
            </Flex>

            <SimpleGrid
                border="1px solid"
                borderColor="gray.300"
                padding="10px"
                columns={3}
                spacing={10}
                marginBottom="50px"
            >
                <Box bg="" height="50px">
                    <Heading size="xs" as="h3" marginBottom="5px">
                        Bedrijf
                    </Heading>
                    <Text>{order?.company}</Text>
                </Box>
                <Box bg="" height="50px">
                    <Heading size="xs" as="h3" marginBottom="5px">
                        Datum - Tijd bestelling
                    </Heading>
                    <Text>
                        {order && order.creationDate > 0
                            ? format(order.creationDate, 'dd/MM/yyyy HH:mm')
                            : '-'}
                    </Text>
                </Box>
                <Box bg="" height="50px">
                    <Heading size="xs" as="h3" marginBottom="5px">
                        E-mailadres
                    </Heading>
                    <Text>{order?.email}</Text>
                </Box>
                <Box bg="" height="50px">
                    <Heading size="xs" as="h3" marginBottom="5px">
                        Bestelstatus
                    </Heading>
                    {order?.status === Status.pending && (
                        <Badge colorScheme="gray">{order?.status}</Badge>
                    )}
                    {order?.status === Status.processing && (
                        <Badge colorScheme="yellow">{order?.status}</Badge>
                    )}
                    {order?.status === Status.ready && (
                        <Badge colorScheme="green">{order?.status}</Badge>
                    )}
                    {order?.status === Status.completed && (
                        <Badge colorScheme="blue">{order?.status}</Badge>
                    )}
                </Box>
            </SimpleGrid>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>E-mail</Th>
                        <Th>Aantal</Th>
                        <Th>Datum - Tijd</Th>
                        <Th>Referentie</Th>
                        <Th>Vorm</Th>
                        <Th textAlign="center">Ontwerp</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {order?.items &&
                        order.items.map(
                            (item: ShoppingCartDto, idx: number) => {
                                return (
                                    <Tr key={`${order.id}-${idx}`}>
                                        <Td>{order.email}</Td>
                                        <Td>{item.amount}</Td>
                                        <Td>
                                            {format(
                                                order.creationDate,
                                                'dd/MM/yyyy HH:mm',
                                            )}
                                        </Td>
                                        <Td>{item.reference}</Td>
                                        <Td>{item.shape}</Td>
                                        <Td>
                                            <a
                                                href={item.image}
                                                download
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Image
                                                    margin="0 auto"
                                                    boxSize="100px"
                                                    objectFit="cover"
                                                    src={item.image}
                                                    alt="Segun Adebayo"
                                                />
                                            </a>
                                        </Td>
                                    </Tr>
                                );
                            },
                        )}
                </Tbody>
            </Table>
        </Layout>
    );
};
