import React, { useState } from 'react';
import { Layout, ShoppingCartDrawer, VTable } from '../components';
import {
    Grid,
    Box,
    Heading,
    useDisclosure,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    Divider,
    Badge,
} from '@chakra-ui/react';
import { getStatusBadge, routes, useAuth } from '../common';
import { useGetOrdersSortedBy } from '../orders';
import { Redirect, useHistory } from 'react-router-dom';
import { OrderDto, Status } from '../common/dto';
import { Row } from 'react-table';
import { format } from 'date-fns';

interface CellProps {
    cell: {
        value: string | number;
    };
}

export const AdminDashboardPage: React.FC = () => {
    const history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { authenticatedUser } = useAuth();

    const { data: orders } = useGetOrdersSortedBy(
        'creationDate',
        'desc',
        authenticatedUser?.isAdmin,
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'E-mail',
                accessor: 'email',
            },
            {
                Header: 'Bedrijf',
                accessor: 'company',
            },
            {
                Header: 'Datum - Tijd',
                accessor: 'creationDate',
                Cell: (cellInfo: CellProps) =>
                    format(cellInfo.cell.value as number, 'dd/MM/yyyy HH:mm'),
            },
            {
                Header: '# ontwerpen',
                accessor: 'items.length',
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (cellInfo: CellProps) =>
                    getStatusBadge(cellInfo.cell.value as string),
            },
        ],
        [],
    );

    const getAmountOfOrdersByStatus = (status: Status) => {
        switch (status) {
            case Status.completed:
                return orders && orders.length > 0
                    ? orders.filter(
                          (order) => order.status === Status.completed,
                      ).length
                    : '-';
            case Status.ready:
                return orders && orders.length > 0
                    ? orders.filter((order) => order.status === Status.ready)
                          .length
                    : '-';
            case Status.processing:
                return orders && orders.length > 0
                    ? orders.filter(
                          (order) => order.status === Status.processing,
                      ).length
                    : '-';
            case Status.pending:
                return orders && orders.length > 0
                    ? orders.filter((order) => order.status === Status.pending)
                          .length
                    : '-';
            default:
                return orders ? orders?.length : '-';
        }
    };

    const handleClickRow = (row: Row<object>) => {
        history.push(`/orders/${(row as Row<OrderDto>).original.id}`);
    };

    if (!authenticatedUser?.isAdmin) {
        return <Redirect to={routes.designer} />;
    }

    return (
        <Layout isLoading={isLoading} onToggleDrawer={onOpen}>
            <ShoppingCartDrawer
                isOpen={isOpen}
                onChangeLoadingState={setIsLoading}
                onOpen={onOpen}
                onClose={onClose}
            />
            <Heading>Admin Board</Heading>
            <Divider variant="vPlexDivider" />
            <Box
                border="1px solid"
                borderColor="gray.200"
                borderRadius="1"
                p="2"
            >
                <Grid
                    templateColumns={{
                        md: 'repeat(4, 1fr)',
                        sm: 'repeat(2, 1fr)',
                    }}
                    gap={6}
                >
                    <Stat>
                        <StatLabel>Bestellingen Afgerond</StatLabel>
                        <StatNumber>
                            {getAmountOfOrdersByStatus(Status.completed)}
                        </StatNumber>
                        <StatHelpText>
                            <Badge colorScheme="blue">Afgerond</Badge>
                        </StatHelpText>
                    </Stat>
                    <Stat>
                        <StatLabel>Klaar voor afhaling</StatLabel>
                        <StatNumber>
                            {getAmountOfOrdersByStatus(Status.ready)}
                        </StatNumber>
                        <StatHelpText>
                            <Badge colorScheme="green">
                                Klaar voor afhaling
                            </Badge>
                        </StatHelpText>
                    </Stat>
                    <Stat>
                        <StatLabel>Bestellingen in behandeling</StatLabel>
                        <StatNumber>
                            {getAmountOfOrdersByStatus(Status.processing)}
                        </StatNumber>
                        <StatHelpText>
                            <Badge colorScheme="yellow">In behandeling</Badge>
                        </StatHelpText>
                    </Stat>
                    <Stat>
                        <StatLabel>Bestelling in wacht</StatLabel>
                        <StatNumber>
                            {getAmountOfOrdersByStatus(Status.pending)}
                        </StatNumber>
                        <StatHelpText>
                            <Badge colorScheme="gray">In afwachting</Badge>
                        </StatHelpText>
                    </Stat>
                </Grid>
            </Box>
            <Box mt="5">
                <Heading size="md">Recentste bestellingen</Heading>
                {orders && orders.length ? (
                    <VTable
                        data={orders}
                        columns={columns}
                        defaultPageSize={5}
                        onClickRow={handleClickRow}
                    />
                ) : (
                    <Badge>Geen bestellingen gevonden.</Badge>
                )}
            </Box>
        </Layout>
    );
};
