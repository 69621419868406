export const TextareaStyling = {
  baseStyle: {
    field: {
      color: "gray.700",
    },
    boxShadow: "none !important"
  },
  variants: {
    flushed: {
      field: {
        _focus: {
          borderColor: 'black_5',
        },
      },
    },
  },
  defaultProps: {
    size: "sm",
    variant: 'flushed',
    focusBorderColor: "black_5",
    _focus: {
      boxShadow: 'none',
    }
  },
};
