import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import {
    IconButton,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Image,
    Avatar,
    useToast,
} from '@chakra-ui/react';
import {
    FiHome,
    FiMenu,
    FiChevronDown,
    FiInfo,
    FiImage,
    FiShoppingBag,
    FiUserPlus,
    FiPackage,
    FiUsers,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { ShoppingCartDto, UserDto } from '../common/dto';
import logo from '../assets/v-plex.png';
import { routes, useAuth } from '../common';
import { NavLink } from 'react-router-dom';
import { getFirebaseErrorMessage } from '../firebase';
import { FirebaseError } from '@firebase/util';
import { IconBadge } from '.';
import { useGetShoppingCartItemsByUserId } from '../shopping-cart';

/* SIDEBAR */
interface SidebarWithHeaderProps {
    user: UserDto | undefined;
    onToggleDrawer: () => void;
    children: ReactNode;
}

export const SidebarWithHeader = ({
    user,
    onToggleDrawer,
    children,
}: SidebarWithHeaderProps): JSX.Element => {
    const { authenticatedUser } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: cartItems } = useGetShoppingCartItemsByUserId(
        authenticatedUser?.userId,
    );

    return (
        <Box minH="100vh" bg={'white'}>
            <SidebarContent
                onClose={() => onClose}
                isAdmin={authenticatedUser?.isAdmin || false}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent>
                    <SidebarContent
                        onClose={onClose}
                        isAdmin={authenticatedUser?.isAdmin || false}
                    />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav
                onOpen={onOpen}
                user={user}
                onToggleDrawer={onToggleDrawer}
                cartItems={cartItems as ShoppingCartDto[]}
            />
            <Box ml={{ base: 0, md: 60 }} p="4">
                {children}
            </Box>
        </Box>
    );
};

/* SIDEBAR */

/* SIDEBAR CONTENT */
interface LinkItemProps {
    name: string;
    path: string;
    icon: IconType;
    isVisible: boolean;
}

interface SidebarProps extends BoxProps {
    isAdmin: boolean;
    onClose: () => void;
}

const SidebarContent = ({ onClose, isAdmin, ...rest }: SidebarProps) => {
    const linkItems: Array<LinkItemProps> = [
        {
            name: 'Dashboard',
            icon: FiHome,
            path: '/dashboard',
            isVisible: !isAdmin,
        },
        {
            name: 'Dashboard',
            icon: FiHome,
            path: '/admin-dashboard',
            isVisible: isAdmin,
        },
        {
            name: 'Ontwerp maken',
            icon: FiImage,
            path: routes.designer,
            isVisible: true,
        },
        {
            name: 'Klantenoverzicht',
            icon: FiUsers,
            path: routes.users,
            isVisible: isAdmin,
        },
        {
            name: 'Nieuwe gebruiker',
            icon: FiUserPlus,
            path: routes.register,
            isVisible: isAdmin,
        },
        {
            name: 'Bestellingsoverzicht',
            icon: FiPackage,
            path: routes.orders,
            isVisible: isAdmin,
        },
        { name: 'FAQ', icon: FiInfo, path: routes.faq, isVisible: true },
    ];

    return (
        <Box
            transition="3s ease"
            bg={'white'}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            zIndex="101"
            {...rest}
        >
            <Flex
                h="20"
                alignItems="center"
                mx="8"
                justifyContent={{ base: 'space-between', md: 'center' }}
            >
                <Image src={logo} w="130px" />
                <CloseButton
                    display={{ base: 'flex', md: 'none' }}
                    onClick={onClose}
                />
            </Flex>
            {linkItems.map((link, idx) => {
                if (!link.isVisible) return null;
                return (
                    <NavItem
                        key={`${link.name}-${idx}`}
                        icon={link.icon}
                        path={link.path}
                    >
                        {link.name}
                    </NavItem>
                );
            })}
        </Box>
    );
};

/* SIDEBAR CONTENT */

/* NAV ITEM */
interface NavItemProps extends FlexProps {
    icon: IconType;
    path: string;
    children: ReactText;
}

const NavItem = ({ icon, path, children, ...rest }: NavItemProps) => {
    return (
        <Link as={NavLink} style={{ textDecoration: 'none' }} to={path}>
            <Flex
                align="center"
                p="4"
                mx="4"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'black',
                    color: 'white',
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

/* NAV ITEM */

/* MOBILENAV */
interface MobileProps extends FlexProps {
    user: UserDto | undefined;
    cartItems?: ShoppingCartDto[];
    onToggleDrawer: () => void;
    onOpen: () => void;
}

const MobileNav = ({
    onOpen,
    user,
    onToggleDrawer,
    cartItems,
    ...rest
}: MobileProps) => {
    const { doSignOut } = useAuth();
    const toast = useToast();
    const history = useHistory();

    const handleSignOut = async () => {
        try {
            await doSignOut();
            toast({
                title: 'U bent succesvol afgemeld!',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: getFirebaseErrorMessage((error as FirebaseError).code),
                duration: 5000,
                status: 'error',
                isClosable: true,
            });
        }
    };

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}
        >
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />
            <Box display={{ base: 'flex', md: 'none' }}>
                <Image src={logo} w="130px" />
            </Box>

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>
                    <IconBadge
                        icon={FiShoppingBag}
                        onClick={onToggleDrawer}
                        counter={cartItems?.length}
                    />
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}
                        >
                            <HStack>
                                <Avatar
                                    backgroundColor="cyanBlue"
                                    size={'md'}
                                    name={`${user?.firstName} ${user?.lastName}`}
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2"
                                >
                                    {/* <Text fontSize="sm">{ `${user?.firstName} ${user?.lastName}` }</Text> */}
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue(
                                'gray.200',
                                'gray.700',
                            )}
                        >
                            <MenuItem
                                onClick={() => history.push(routes.dashboard)}
                            >
                                Dashboard
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => handleSignOut()}>
                                Afmelden
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};

/* MOBILENAV */
