import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as yup from 'yup';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { routes } from '../common';
import { getFirebaseErrorMessage } from '../firebase';
import { SignInData, useAuth } from '../common/context/auth.context';
import { FirebaseError } from '@firebase/util';
import {
    Center,
    FormControl,
    Input,
    Stack,
    Button,
    FormErrorMessage,
    Text,
    useToast,
} from '@chakra-ui/react';
import { Card } from '../components';

type AuthErrorMessage = {
    error: string;
};

const validationSchema: yup.SchemaOf<SignInData> = yup.object().shape({
    email: yup
        .string()
        .email('Geen geldig e-mailadres.')
        .required('E-mailadres is een verplicht veld.'),
    password: yup
        .string()
        .required('Wachtwoord is een verplicht veld')
        .min(6, 'Het wachtwoord moet uit minstens 6 karakters bestaan.'),
});

export const LoginPage: React.FC = () => {
    const history = useHistory();
    const { authenticatedUser, doSignInWithEmailAndPassword } = useAuth();
    const [authErrors, setAuthErrors] = useState<AuthErrorMessage | null>(null);
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<SignInData>({
        resolver: yupResolver(validationSchema),
    });

    const toast = useToast();

    const onSubmit = handleSubmit(async (formData: SignInData) => {
        try {
            await doSignInWithEmailAndPassword(formData);
            toast({
                title: 'U bent succesvol ingelogd!',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error: unknown) {
            setAuthErrors({
                error: getFirebaseErrorMessage((error as FirebaseError).code),
            });
            toast({
                title: getFirebaseErrorMessage((error as FirebaseError).code),
                duration: 5000,
                status: 'error',
                isClosable: true,
            });
            console.error(error);
        }
    });

    useEffect(() => {
        if (authenticatedUser && !authenticatedUser.isAdmin) {
            history.push(routes.dashboard);
        } else if (authenticatedUser && authenticatedUser.isAdmin) {
            history.push(routes.admin_dashboard);
        }
    }, [authenticatedUser, history]);

    return (
        <Center bgPos={'center'} flexDir="column">
            <p>DOMAIN: {process.env.REACT_APP_AUTHDOMAIN}</p>
            <Card header={'V-Plex'}>
                <form
                    style={{ width: '100%', maxWidth: '100%' }}
                    onSubmit={onSubmit}
                >
                    <FormControl
                        mb="2"
                        isRequired
                        isInvalid={!!errors?.email?.message}
                    >
                        <Input
                            {...register('email')}
                            type="email"
                            isfullwidth="true"
                            placeholder={'E-mailadres'}
                            p={2}
                        />
                        <FormErrorMessage>
                            {errors.email?.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl
                        isRequired
                        isInvalid={!!errors?.password?.message}
                    >
                        <Input
                            {...register('password')}
                            isfullwidth="true"
                            placeholder={'Wachtwoord'}
                            p={2}
                            type="password"
                        />
                        <FormErrorMessage>
                            {errors.password?.message}
                        </FormErrorMessage>
                    </FormControl>
                    <Stack pt={10}>
                        <Button type="submit" variant="primary">
                            Aanmelden
                        </Button>
                        <Stack
                            direction={{ base: 'column', sm: 'row' }}
                            align={'center'}
                            justify={'center'}
                            mt={1}
                        >
                            <SmLink to={routes.resetPassword}>
                                Wachtwoord vergeten
                            </SmLink>
                        </Stack>
                    </Stack>

                    <Text variant={'error'}>{authErrors?.error}</Text>
                </form>
            </Card>
        </Center>
    );
};

const SmLink = styled(Link)`
    color: rgba(34, 34, 34, 0.5);
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.5s ease;

    &:hover {
        color: #222;
    }
`;
