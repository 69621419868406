import React from 'react';
import { IconBaseProps } from 'react-icons';

import { Icon } from '@chakra-ui/react';
import styled from 'styled-components';

interface IconBadgeProps {
    icon: (props: IconBaseProps) => JSX.Element;
    counter?: number;
    onClick: () => void;
}

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin: 10px;
    font-size: 21px;
    background: #222;
    color: white;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
`;

const Badge = styled.div`
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    border: 1px solid #222;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background-color: white;
    color: #222;
    font-size: 10px;
    font-weight: 700;
`;

export const IconBadge: React.FC<IconBadgeProps> = ({ icon, counter, onClick }) => {
    return <IconWrapper onClick={ onClick }>
        { <Icon as={ icon } /> }
        { counter && counter > 0 ? <Badge>{ counter }</Badge> : <></> }
    </IconWrapper>
}