import React from 'react';
import { useTable, usePagination, Row } from 'react-table';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Flex,
    IconButton,
    Text,
    Tooltip,
    Select,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react';
import {
    FiArrowLeft,
    FiArrowRight,
    FiChevronsLeft,
    FiChevronsRight,
} from 'react-icons/fi';

interface TableProps {
    data: readonly object[];
    columns: {
        Header: string;
        accessor?: string;
    }[];
    defaultPageSize?: number;
    onClickRow?: (row: Row<object>) => void;
}

export const VTable: React.FC<TableProps> = ({
    columns,
    data,
    onClickRow,
    defaultPageSize = 10,
}) => {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: defaultPageSize },
        },
        usePagination,
    );

    // Render the UI for your table
    return (
        <>
            <Table {...getTableProps()}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <Tr
                                {...row.getRowProps()}
                                onClick={() => onClickRow && onClickRow(row)}
                                _hover={{
                                    backgroundColor: 'gray.100',
                                    cursor: 'pointer',
                                    transition: 'all .2s ease',
                                }}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <Td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>

            <Flex justifyContent="space-between" m={4} alignItems="center">
                <Flex>
                    <Tooltip label="Eerste pagina">
                        <IconButton
                            colorScheme="blackAlpha"
                            aria-label="Eerste pagina"
                            onClick={() => gotoPage(0)}
                            isDisabled={!canPreviousPage}
                            icon={<FiChevronsLeft />}
                            mr={4}
                        />
                    </Tooltip>
                    <Tooltip label="Vorige pagina">
                        <IconButton
                            colorScheme="blackAlpha"
                            aria-label="Vorige pagina"
                            onClick={previousPage}
                            isDisabled={!canPreviousPage}
                            icon={<FiArrowLeft />}
                        />
                    </Tooltip>
                </Flex>

                <Flex alignItems="center">
                    <Text flexShrink={0} ml={2} mr={2}>
                        Pagina{' '}
                        <Text fontWeight="bold" as="span">
                            {pageIndex + 1}
                        </Text>{' '}
                        van{' '}
                        <Text fontWeight="bold" as="span">
                            {pageOptions.length}
                        </Text>
                    </Text>
                    <Text flexShrink={0}>Ga naar pagina:</Text>{' '}
                    <NumberInput
                        ml={2}
                        mr={8}
                        w={28}
                        min={1}
                        max={pageOptions.length}
                        onChange={(value) => {
                            const page = value ? parseInt(value, 10) - 1 : 0;
                            gotoPage(page);
                        }}
                        defaultValue={pageIndex + 1}
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                    <Select
                        w={32}
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Toon {pageSize}
                            </option>
                        ))}
                    </Select>
                </Flex>

                <Flex>
                    <Tooltip label="Volgende pagina">
                        <IconButton
                            colorScheme="blackAlpha"
                            aria-label="Volgende pagina"
                            onClick={nextPage}
                            isDisabled={!canNextPage}
                            icon={<FiArrowRight />}
                        />
                    </Tooltip>
                    <Tooltip label="Laatste pagina">
                        <IconButton
                            colorScheme="blackAlpha"
                            aria-label="Laatste pagina"
                            onClick={() => gotoPage(pageCount - 1)}
                            isDisabled={!canNextPage}
                            icon={<FiChevronsRight />}
                            ml={4}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
        </>
    );
};
