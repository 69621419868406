import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { routes, useAuth } from '../common';
import { LoadingSpinner } from '.';


export type PrivateRouteProps = {
    isAuthenticated: boolean;
    authenticationPath: string;
} & RouteProps;

export const PrivateRoute: React.FC<RouteProps> = ({ component, ...rest }: RouteProps) => {

    const { authenticatedUser, isAuthenticating } = useAuth();

    if (isAuthenticating) {
        return <LoadingSpinner />
    }

    return authenticatedUser
        ? <Route { ...rest } component={ component } />
        : <Redirect to={ { pathname: routes.login } } />

};