import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { Layout, ShoppingCartDrawer } from '../components';
import { useForm } from 'react-hook-form';
import {
    FormErrorMessage,
    FormControl,
    Input,
    Button,
    Flex,
    useToast,
    Checkbox,
    Divider,
    Heading,
    useDisclosure,
} from '@chakra-ui/react';
import { UserDto } from '../common/dto';
import { yupResolver } from '@hookform/resolvers/yup';
import { generate } from 'generate-password';
import { doCreateUserWithEmailAndPassword, doPasswordReset } from '../firebase';
import { useCreateUser, useGetUser, useUpdateUser } from '../users';
import { useParams } from 'react-router-dom';
import { routes, useAuth } from '../common';

interface RegisterFormData {
    firstName: UserDto['firstName'];
    lastName: UserDto['lastName'];
    email: UserDto['email'];
    password?: string;
    businessFunction: UserDto['businessFunction'];
    company: UserDto['company'];
    vatNumber: UserDto['vatNumber'];
    phone: UserDto['phone'];
    address: UserDto['address'];
    city: UserDto['city'];
    isAdmin: UserDto['isAdmin'];
}

const validationSchema: yup.SchemaOf<Omit<RegisterFormData, 'password'>> = yup
    .object()
    .shape({
        firstName: yup.string().required('Voornaam is een verplicht veld.'),
        lastName: yup.string().required('Familienaam is een verplicht veld.'),
        email: yup
            .string()
            .email('Geen geldig e-mailadres.')
            .required('E-mailadres is een verplicht veld.'),
        businessFunction: yup
            .string()
            .required('Functie is een verplicht veld.'),
        company: yup.string().required('Bedrijf is een verplicht veld.'),
        address: yup.string().required('Adres is een verplicht veld.'),
        city: yup.string().required('Stad is een verplicht veld.'),
        vatNumber: yup.string().required('BTW-nummer is een verplicht veld.'),
        phone: yup.string().required('Telefoon is een verplicht veld.'),
        isAdmin: yup.boolean().required(),
    });

interface UserDetailParams {
    id: string;
}

export const UserDetailPage: React.FC = () => {
    const { onClose, onOpen, isOpen } = useDisclosure();
    const { id } = useParams<UserDetailParams>();
    const toast = useToast();
    const [password, setPassword] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { authenticatedUser } = useAuth();

    const { mutateAsync: createUser } = useCreateUser();
    const { mutateAsync: updateUser } = useUpdateUser();
    const { data: user } = useGetUser(id, authenticatedUser?.isAdmin);

    const {
        handleSubmit,
        register,
        reset,
        setValue,
        formState: { errors, isValid, isDirty },
    } = useForm<RegisterFormData>({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (user) {
            setValue('firstName', user.firstName);
            setValue('lastName', user.lastName);
            setValue('email', user.email);
            setValue('company', user.company);
            setValue('phone', user.phone);
            setValue('businessFunction', user.businessFunction);
            setValue('address', user.address);
            setValue('city', user.city);
            setValue('vatNumber', user.vatNumber);
            setValue('isAdmin', user.isAdmin);
        } else {
            reset();
        }
    }, [user, setValue, reset]);

    const handleGeneratePassword = () => {
        const generatedPassword = generate({ length: 8, numbers: true });
        setValue('password', generatedPassword);
        setPassword(generatedPassword);
    };

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value);
    };

    const onSubmit = async (formData: RegisterFormData) => {
        if (user) {
            try {
                setIsLoading(true);
                const { password, ...userDataToSave } = formData;
                await updateUser({
                    id: user.userId,
                    data: {
                        userId: user.userId,
                        ...userDataToSave,
                    },
                });

                toast({
                    title: 'Gebruiker succesvol aangepast.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                toast({
                    title: 'Oeps, er liep iets fout. Probeer het later opnieuw.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            try {
                setIsLoading(true);
                if (formData.password) {
                    const userCredential =
                        await doCreateUserWithEmailAndPassword({
                            email: formData.email,
                            password: formData.password,
                        });

                    const { password, ...userDataToSave } = formData;

                    await createUser({
                        id: userCredential.user.uid,
                        data: {
                            userId: userCredential.user.uid,
                            ...userDataToSave,
                        },
                    });
                    await doPasswordReset(formData.email);
                    toast({
                        title: 'Gebruiker succesvol aangemaakt.',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                    reset();
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                toast({
                    title: 'Oeps, er liep iets fout. Probeer het later opnieuw.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    const isInvalidForm = (): boolean => {
        return !isDirty || !isValid || (!user && !password.length);
    };

    if (!authenticatedUser?.isAdmin) {
        return <Redirect to={routes.designer} />;
    }

    return (
        <Layout isLoading={isLoading} onToggleDrawer={onOpen}>
            <ShoppingCartDrawer
                isOpen={isOpen}
                onChangeLoadingState={setIsLoading}
                onOpen={onOpen}
                onClose={onClose}
            />
            <Heading as="h1">Nieuwe gebruiker aanmaken</Heading>
            <Divider variant="vPlexDivider" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flex marginBottom="5">
                    <FormControl
                        marginRight="10px"
                        isInvalid={!!errors.firstName}
                    >
                        <Input
                            {...register('firstName')}
                            isfullwidth="true"
                            placeholder={'Voornaam'}
                            p={2}
                        />
                        {errors.firstName && (
                            <FormErrorMessage>
                                {errors.firstName?.message}
                            </FormErrorMessage>
                        )}
                    </FormControl>
                    <FormControl isInvalid={!!errors.lastName}>
                        <Input
                            {...register('lastName')}
                            isfullwidth="true"
                            placeholder={'Familienaam'}
                            p={2}
                        />
                        {errors.lastName && (
                            <FormErrorMessage>
                                {errors.lastName?.message}
                            </FormErrorMessage>
                        )}
                    </FormControl>
                </Flex>
                <Flex marginBottom="5">
                    <FormControl marginRight="10px" isInvalid={!!errors.email}>
                        <Input
                            {...register('email')}
                            type="email"
                            isfullwidth="true"
                            placeholder={'E-mailadres'}
                            p={2}
                        />
                        <FormErrorMessage>
                            {errors.email?.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.phone}>
                        <Input
                            {...register('phone')}
                            isfullwidth="true"
                            placeholder={'Telefoon'}
                            p={2}
                        />
                        <FormErrorMessage>
                            {errors.phone?.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                {!user && (
                    <Flex marginBottom="5">
                        <FormControl
                            marginRight="10px"
                            isInvalid={!!errors.password}
                        >
                            <Input
                                {...register('password')}
                                type="text"
                                value={password}
                                isfullwidth="true"
                                isRequired={!user}
                                placeholder={'Wachtwoord'}
                                p={2}
                                onChange={handleChangePassword}
                            />
                            <FormErrorMessage>
                                {errors.password?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Button
                            onClick={handleGeneratePassword}
                            aria-label="genereer"
                            background={'black'}
                            size="sm"
                            isDisabled={!!user}
                            variant="primary"
                        >
                            Genereer
                        </Button>
                    </Flex>
                )}
                <Flex marginBottom="5">
                    <FormControl
                        marginRight="10px"
                        isInvalid={!!errors.businessFunction}
                    >
                        <Input
                            {...register('businessFunction')}
                            type="text"
                            isfullwidth="true"
                            placeholder={'Functie'}
                            p={2}
                        />
                        <FormErrorMessage>
                            {errors.businessFunction?.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.company}>
                        <Input
                            {...register('company')}
                            isfullwidth="true"
                            placeholder={'Bedrijfsnaam'}
                            p={2}
                        />
                        <FormErrorMessage>
                            {errors.company?.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex marginBottom="5">
                    <FormControl
                        marginRight="10px"
                        isInvalid={!!errors.vatNumber}
                    >
                        <Input
                            {...register('vatNumber')}
                            type="text"
                            isfullwidth="true"
                            placeholder={'BTW-nummer'}
                            p={2}
                        />
                        <FormErrorMessage>
                            {errors.vatNumber?.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex marginBottom="5">
                    <FormControl
                        marginRight="10px"
                        isInvalid={!!errors.address}
                    >
                        <Input
                            {...register('address')}
                            type="text"
                            isfullwidth="true"
                            placeholder={'Straat - nummer'}
                            p={2}
                        />
                        <FormErrorMessage>
                            {errors.address?.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.city}>
                        <Input
                            {...register('city')}
                            isfullwidth="true"
                            placeholder={'Stad/Gemeente'}
                            p={2}
                        />
                        <FormErrorMessage>
                            {errors.city?.message}
                        </FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex>
                    <Checkbox
                        {...register('isAdmin')}
                        defaultChecked={user && user?.isAdmin}
                    >
                        Administrator
                    </Checkbox>
                </Flex>
                <Button
                    mt={5}
                    variant="primary"
                    type="submit"
                    disabled={isInvalidForm()}
                >
                    {!user ? 'Gebruiker aanmaken' : 'Gebruiker wijzigen'}
                </Button>
            </form>
        </Layout>
    );
};
