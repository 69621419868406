import { getDoc, setDoc, updateDoc } from "@firebase/firestore";
import { UserDto } from "../../common/dto";
import { FIRESTORE_PATH } from "../../common/enums";
import { firestoreDocWithPathSegments, userDocs } from "../../firebase";
import { CreateUserEntry, UpdateUserEntry } from "../types/create-user-entry.type";

export const getAllUsers = async () => {
    const snapshot = await userDocs();

    return snapshot.docs.map((doc) => {
        return doc.data();
    });
}

export const createUser = async ({id, data}: CreateUserEntry) => {
    await setDoc(firestoreDocWithPathSegments(FIRESTORE_PATH.USERS, id), data);
}

export const getUser = async (userId: string): Promise<UserDto> => {
    try {
        const userDocRef = firestoreDocWithPathSegments(FIRESTORE_PATH.USERS, userId);
        const snapshot = await getDoc(userDocRef);
        const user = snapshot.data();

        return {
            userId,
            ...user
        } as UserDto;
    } catch (error) {
        throw error;
    }
}

export const updateUser = async ({id, data}: UpdateUserEntry) => {
    const userRef = firestoreDocWithPathSegments(FIRESTORE_PATH.USERS, id);
    await updateDoc(userRef, data);
}

