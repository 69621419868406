import React, { useState, useEffect } from 'react';
import * as htmlToImage from 'html-to-image';
import {
    Designer,
    Layout,
    MockResult,
    ShoppingCartDrawer,
} from '../components';
import { Shape, ShoppingCartDto } from '../common/dto';
import { storageTask } from '../firebase';
import { Flex, Box, useDisclosure } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useCreateShoppingCartItem } from '../shopping-cart';
import { getDownloadURL, UploadResult } from '@firebase/storage';
import { useAuth } from '../common';
import { v4 as uuidv4 } from 'uuid';
import { useQueryClient } from 'react-query';
import { FIRESTORE_PATH } from '../common/enums';

type TextAlignment = 'left' | 'center' | 'right';
const defaultSrc =
    'https://images.pexels.com/photos/3831631/pexels-photo-3831631.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260';

export const DesignPage: React.FC = () => {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [reference, setReference] = useState<string>('');
    const [ashesSide, setAshesSide] = useState<string>('L');
    const [shape, setShape] = useState<Shape>(Shape.square);
    const [cropData, setCropData] = useState<string>();
    const [cropper, setCropper] = useState<Cropper>();
    const [isFontBoldMsg, setIsFontBoldMsg] = useState<boolean>(false);
    const [isFontBoldName, setIsFontBoldName] = useState<boolean>(false);
    const [isFontItalic, setIsFontItalic] = useState<boolean>(false);
    const [fontFamily, setFontFamily] = useState('Open Sans');
    const [textAlignment, setTextAlignment] = useState<TextAlignment>('left');
    const [fontSize, setFontSize] = useState<number>(16);
    const [image, setImage] = useState<string | undefined>(defaultSrc);
    const [imageFilter, setImageFilter] = useState<string>('');
    const [rotation, setRotation] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { authenticatedUser } = useAuth();
    const queryClient = useQueryClient();

    const { mutateAsync: createShoppingCartItem } = useCreateShoppingCartItem();

    const cleanup = () => {
        setIsFontItalic(false);
        setIsFontBoldName(false);
        setIsFontBoldMsg(false);
        setTextAlignment('left');
        setFontFamily('Open Sans');
        setLastName('');
        setFirstName('');
        setCropData('');
        setMessage('');
        setImageFilter('');
        setFontSize(16);
    };

    useEffect(() => {
        cropper && cropper.rotateTo(rotation);
    }, [rotation, cropper]);

    const handleCropData = (data: string) => {
        setCropData(data);
    };

    const handleChangeFontFamily = (fontFamily: string) => {
        switch (fontFamily) {
            case 'serif':
                setFontFamily('Lora');
                break;
            case 'sans-serif':
                setFontFamily('Open Sans');
                break;
            case 'handscript':
                setFontFamily('Dancing Script');
                break;
            default:
                setFontFamily('Open Sans');
        }
    };

    const handleChangeFontStyle = () => {
        setIsFontItalic((prevState) => !prevState);
    };

    const handleChangeFontWeight = (fontWeight: string) => {
        fontWeight === 'fullname'
            ? setIsFontBoldName((prevState) => !prevState)
            : setIsFontBoldMsg((prevState) => !prevState);
    };

    const handleChangeTextAlignment = (textAlignment: TextAlignment) => {
        setTextAlignment(textAlignment);
    };

    const handleChangeFirstName = (firstName: string) => {
        setFirstName(firstName);
    };

    const handleChangeLastName = (lastName: string) => {
        setLastName(lastName);
    };

    const handleChangeMessage = (message: string) => {
        setMessage(message);
    };

    const handleChangeReference = (reference: string) => {
        setReference(reference);
    };

    const handleChangeShape = (shape: Shape) => {
        setShape(shape);
    };

    const handleChangeFontSize = (fontSize: number) => {
        setFontSize(fontSize);
    };

    const handleChangeImageFilter = (imageFilter: string) => {
        setImageFilter(imageFilter);
    };

    const handleCropperChange = (cropper: Cropper | undefined) => {
        setCropper(cropper);
    };

    const handleChangeRotation = (direction: string) => {
        if (direction === 'left') {
            setRotation((rotation) => {
                if (rotation === 360 || rotation === -360) rotation = 0;
                return rotation - 90;
            });
        } else {
            setRotation((rotation) => {
                if (rotation === 360 || rotation === -360) rotation = 0;
                return rotation + 90;
            });
        }
    };

    const handleChangeFileUpload = (files: FileList) => {
        const reader = new FileReader();
        reader.onload = () => {
            setImage(`${reader.result}`);
        };
        reader.readAsDataURL(files[0]);
    };

    const handleChangeAshesSide = (side: string) => {
        setAshesSide(side);
    };

    const handleSubmitResult = async () => {
        let mock = document.getElementById('mock-result');

        if (mock && authenticatedUser) {
            try {
                setIsLoading(true);
                const id = uuidv4();

                const file = await htmlToImage.toBlob(mock);
                const uploadResult = (await storageTask(
                    file,
                    id,
                )) as UploadResult;
                const url = await getDownloadURL(uploadResult.ref);

                const cartItem: ShoppingCartDto = {
                    id,
                    amount: 1,
                    image: url,
                    reference,
                    shape,
                    userId: authenticatedUser.userId,
                };

                await createShoppingCartItem(
                    { id, data: cartItem },
                    {
                        onSuccess: () =>
                            queryClient.invalidateQueries(
                                FIRESTORE_PATH.SHOPPING_CART,
                            ),
                    },
                );

                toast({
                    title: 'Het ontwerp werd succesvol in het winkelmandje geplaatst.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onOpen();
                cleanup();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                toast({
                    title: 'Oeps, er liep iets fout. Probeer het later opnieuw.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    return (
        <Layout isLoading={isLoading} onToggleDrawer={onOpen}>
            <ShoppingCartDrawer
                isOpen={isOpen}
                onChangeLoadingState={setIsLoading}
                onOpen={onOpen}
                onClose={onClose}
            />
            <Flex marginTop="15px" padding="0 40px">
                <Box maxW="510px">
                    <Designer
                        image={image}
                        onChangeCropper={handleCropperChange}
                        onChangeFontFamily={handleChangeFontFamily}
                        onChangeFontStyle={handleChangeFontStyle}
                        onChangeFontWeight={handleChangeFontWeight}
                        onChangeTextAlignment={handleChangeTextAlignment}
                        onChangeFirstName={handleChangeFirstName}
                        onChangeLastName={handleChangeLastName}
                        onChangeMessage={handleChangeMessage}
                        onChangeReference={handleChangeReference}
                        onChangeShape={handleChangeShape}
                        onChangeCropData={handleCropData}
                        onChangeFontSize={handleChangeFontSize}
                        onChangeImageFilter={handleChangeImageFilter}
                        onChangeRotation={handleChangeRotation}
                        onChangeFileUpload={handleChangeFileUpload}
                        onChangeAshesSide={handleChangeAshesSide}
                        onSubmitResult={handleSubmitResult}
                    />
                </Box>
                <Box marginLeft="20px" alignSelf="center">
                    <MockResult
                        cropData={cropData}
                        textAlignment={textAlignment}
                        fontFamily={fontFamily}
                        isFontBoldMsg={isFontBoldMsg}
                        isFontBoldName={isFontBoldName}
                        isFontItalic={isFontItalic}
                        firstName={firstName}
                        lastName={lastName}
                        message={message}
                        fontSize={fontSize}
                        imageFilter={imageFilter}
                        ashesSide={ashesSide}
                    />
                </Box>
            </Flex>
        </Layout>
    );
};
