import { Badge } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Status } from '../dto';

export const getStatusBadge = (value: string): ReactNode => {
    switch (value) {
        case Status.pending:
            return <Badge colorScheme="gray">{value}</Badge>;
        case Status.processing:
            return <Badge colorScheme="yellow">{value}</Badge>;
        case Status.ready:
            return <Badge colorScheme="green">{value}</Badge>;
        case Status.completed:
            return <Badge colorScheme="blue">{value}</Badge>;
        default:
            return <Badge colorScheme="gray">{value}</Badge>;
    }
};
