export const ButtonStyling = {
  baseStyle: {
    borderRadius: 0,
    textTransform: "uppercase",
    color: "white",
    border: "none",
    fontWeight: "600",
    fontFamily: 'Montserrat'
  },
  variants: {
    primary: {
      backgroundColor: "black",
      fontSize: "xs",
      _hover: {
        backgroundColor: "blackHover",
        _disabled: {
          backgroundColor: "blackHover",
        }
      },
      _disabled: {
        backgroundColor: "blackHover",
      }
    },
    secondary: {
      backgroundColor: "white",
      border: "1px solid black",
      color: "black",
      fontSize: "xs",
      _hover: {
        backgroundColor: "smokeWhite",
      },
    },
    flat: {
      backgroundColor: "white",
      border: "none",
      color: "black",
      fontSize: "10px",
      padding: "0 5px",
      _hover: {
        backgroundColor: "var(--chakra-colors-blackAlpha-600)",
        color: "white",
      },
      _active: {
        backgroundColor: "var(--chakra-colors-blackAlpha-700)",
        color: "white"
      }
    },
    buttonBox: {
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      // rgb(0 0 0 / 10%) 0px 20px 25px, rgb(0 0 0 / 4%) 0px 10px 10px
      border: '1px solid',
      borderColor: 'gray.100',
      backgroundColor: "white",
      fontSize: 'sm',
      textAlign: 'center',
      color: "black",
      minH:"100px",
      maxh: "100px",
      whiteSpace: 'normal',
      _hover: {
        backgroundColor: 'rgba(0,0,0, .01)'
      }
    }
  },
};
