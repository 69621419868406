import React, { Fragment, useState } from 'react';
import { Layout, VTable, ShoppingCartDrawer } from '../components';
import { Divider, Heading, useDisclosure } from '@chakra-ui/react';
import { useHistory, Redirect } from 'react-router-dom';
import { useGetUsers } from '../users';
import { UserDto } from '../common/dto';
import { Row } from 'react-table';
import { routes, useAuth } from '../common';

interface CellProps {
    cell: {
        row: {
            original: UserDto;
        };
    };
}

export const UsersOverviewPage: React.FC = () => {
    const { onClose, onOpen, isOpen } = useDisclosure();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { authenticatedUser } = useAuth();

    const { data: users, isLoading: isFetching } = useGetUsers(
        authenticatedUser?.isAdmin,
    );

    const handleClickRow = (row: Row<object>) => {
        history.push(`/users/${(row as Row<UserDto>).original.userId}`);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Naam',
                accessor: `lastName`,
                Cell: (props: CellProps) => (
                    <Fragment>{`${props.cell.row.original.firstName} ${props.cell.row.original.lastName}`}</Fragment>
                ),
            },
            {
                Header: 'Bedrijf',
                accessor: 'company',
            },
            {
                Header: 'E-mail',
                accessor: 'email',
            },
            {
                Header: 'Telefoon',
                accessor: 'phone',
            },
        ],
        [],
    );

    if (!authenticatedUser?.isAdmin) {
        return <Redirect to={routes.designer} />;
    }

    return (
        <Layout isLoading={isFetching || isLoading} onToggleDrawer={onOpen}>
            <ShoppingCartDrawer
                isOpen={isOpen}
                onChangeLoadingState={setIsLoading}
                onOpen={onOpen}
                onClose={onClose}
            />
            <Heading as="h1">gebruikersoverzicht</Heading>
            <Divider variant="vPlexDivider" />

            {users?.length && (
                <VTable
                    data={users}
                    columns={columns}
                    onClickRow={handleClickRow}
                />
            )}
        </Layout>
    );
};
