import React from 'react'
import {
    Image,
    Stack,
    Center,
    Spinner
} from '@chakra-ui/react';
import logo from '../assets/v-plex.png'

export const LoadingSpinner: React.FC = () => {
    return (
        <Center
            h="100%"
            w="100%"
            flexDirection="column"
            bg="white"
            position="fixed"
            top="0"
            left="83px"
            bottom="0"
            right="0"
            zIndex="100"
            overflow="hidden !important"
        >
            <Stack maxW="315px" h="auto" mb="20px">
                <Image src={ logo } w="315px" />
            </Stack>
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="black"
                size="xl"
            />
        </Center>
    );
}