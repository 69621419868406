import React from 'react';
import {
    Heading,
    Box,
    Center,
    Stack,
    useColorModeValue,
} from '@chakra-ui/react';

interface CardProps {
    header: string;
}

export const Card: React.FC<CardProps> = ({ header, children }) => {
    return (
        <Center h="100vh">
            <Box
                maxW={ '450px' }
                minW={ '450px' }
                w={ 'full' }
                bg={ useColorModeValue('white', 'gray.800') }
                boxShadow={ '2xl' }
                rounded={ 'sm' }
                overflow={ 'hidden' }>
                <Box p={ 0 }>
                    <Stack spacing={ 6 } align={ 'center' } mb={ 5 } bg="black" p={ '4' }>
                        <Heading color="white" size="md" mb="0">
                            { header }
                        </Heading>
                    </Stack>
                    <Box p={ 4 }>
                        <Stack direction={ 'row' } justify={ 'start' } spacing={ 6 }>
                            { children }
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </Center>
    );
}