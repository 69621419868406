import { getStorage, ref, uploadBytes, UploadResult } from "firebase/storage";

const storage = getStorage();

const metadata = {
    contentType: 'image/jpeg'
};

export const storageTask = async (file: Blob | Uint8Array | ArrayBuffer | undefined | null, uid: string): Promise<UploadResult | string> => {
    const storageRef = ref(storage, `images/${uid}`);

    if (file) {
        return await uploadBytes(storageRef, file, metadata);
    }

    return '';
}