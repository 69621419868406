export const RadioStyling = {
  baseStyle: {
    control: {
      cursor: "pointer",
    },
    label: {
      cursor: "pointer",
    },
  },
  variants: {
    vPlexRadio: {
      control: {
        _checked: {
          background: "gray.800",
          borderColor: "gray.800",
          _hover: {
            background: "gray.600",
            borderColor: "gray.600",
          },
        },

        _focus: {
          boxShadow: "0 0 0 3px rgba(0, 0, 0, 0.3)", // Must be an RGBA color, otherwise it's an invalid value
        },
      },
    },
    switchRadio: {
      control: {
        display: 'none',
      },
      label: {
        color: 'black_5',
        fontSize: '12px',
        fontWeight: '600',
        padding: 3,
        paddingTop:.5,
        paddingBottom:.5,
        marginLeft: 0,
        _checked: {
          color: 'black',
          borderBottom: "1px solid",
          borderColor: "gray.600",
          background: 'smokeWhite'
        }
      },
    }
  },
  defaultProps: {
    variant: "vPlexRadio",
  },
};
