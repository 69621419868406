export const LinkStyling = {
    variants: {
        small: {
            fontSize: "xs",
            textDecoration: "underline",
            color: "black_5",
            _hover: {
                color: 'black'
            }
        }
    }
}