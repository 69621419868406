import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, UserCredential } from '@firebase/auth';
import {
    doCreateUserWithEmailAndPassword,
    doSignInWithEmailAndPassword,
    doSignOut,
} from '../../firebase';
import { getUser } from '../../users';
import { UserDto } from '../dto';

export interface SignInData {
    email: string;
    password: string;
}

export interface AuthenticationContextType {
    authenticatedUser: UserDto | undefined;
    setAuthenticatedUser:
        | React.Dispatch<React.SetStateAction<UserDto | undefined>>
        | undefined;
    doSignInWithEmailAndPassword: (data: SignInData) => Promise<void>;
    doCreateUserWithEmailAndPassword: (
        data: SignInData,
    ) => Promise<UserCredential>;
    doSignOut: () => Promise<void>;
    isAuthenticating: boolean;
}

const AuthenticationContext = createContext<AuthenticationContextType>(
    {} as AuthenticationContextType,
);
const auth = getAuth();

export const AuthenticationProvider: React.FC = ({ children }) => {
    const [authenticatedUser, setAuthenticatedUser] =
        useState<AuthenticationContextType['authenticatedUser']>(undefined);
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);

    const value: AuthenticationContextType = {
        authenticatedUser,
        setAuthenticatedUser,
        isAuthenticating,
        doSignInWithEmailAndPassword,
        doCreateUserWithEmailAndPassword,
        doSignOut,
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (!user) {
                setAuthenticatedUser(undefined);
                setIsAuthenticating(false);
                return;
            }

            const { userId, ...data } = await getUser(user.uid);

            setAuthenticatedUser({ userId, ...data } as UserDto);
            setIsAuthenticating(false);
        });

        return () => unsubscribe();
    }, []);

    return (
        <AuthenticationContext.Provider value={value}>
            {children}{' '}
        </AuthenticationContext.Provider>
    );
};

export const useAuth = (): AuthenticationContextType => {
    return useContext(AuthenticationContext);
};
