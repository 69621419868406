import React from 'react';
import {
    Box,
    Button,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    useToast,
    FormLabel,
    IconButton,
    Image,
    Input,
    Tag,
    Text,
} from '@chakra-ui/react';
import {
    useDeleteShoppingCartItem,
    useGetShoppingCartItemsByUserId,
    useUpdateShoppingCartItem,
} from '../shopping-cart';
import { useCreateOrder } from '../orders';
import { FiTrash } from 'react-icons/fi';
import { OrderDto, ShoppingCartDto, Status } from '../common/dto';
import { useAuth } from '../common';
import { useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { FIRESTORE_PATH } from '../common/enums';

interface ShoppingCartDrawerProps {
    isOpen: boolean;
    onChangeLoadingState: (isLoading: boolean) => void;
    onOpen: () => void;
    onClose: () => void;
}

export const ShoppingCartDrawer: React.FC<ShoppingCartDrawerProps> = ({
    onChangeLoadingState,
    isOpen,
    onClose,
}) => {
    const { authenticatedUser } = useAuth();
    const queryClient = useQueryClient();
    const toast = useToast();

    const { data: shoppingCartItems } = useGetShoppingCartItemsByUserId(
        authenticatedUser?.userId,
    );
    const { mutateAsync: updateShoppingCartItem } = useUpdateShoppingCartItem();
    const { mutateAsync: deleteShoppingCartItem } = useDeleteShoppingCartItem();
    const { mutateAsync: createOrder } = useCreateOrder();

    const onChangeAmount = (amount: number, item: ShoppingCartDto) => {
        if (amount > 0 && !isNaN(amount)) {
            try {
                updateShoppingCartItem({
                    id: item.id,
                    data: {
                        amount,
                    },
                });
            } catch (error) {
                console.error('error:', error);
            }
        }
    };

    const onDeleteItem = (item: ShoppingCartDto) => {
        deleteShoppingCartItem(item.id, {
            onSuccess: () =>
                queryClient.invalidateQueries(FIRESTORE_PATH.SHOPPING_CART),
        });
    };

    const onSubmitOrder = async () => {
        if (authenticatedUser) {
            try {
                onChangeLoadingState(true);
                const id = uuidv4();
                const { userId, email, company } = authenticatedUser;

                const order: OrderDto = {
                    id,
                    company,
                    email,
                    userId,
                    items: shoppingCartItems as ShoppingCartDto[],
                    status: Status.pending,
                    creationDate: Date.now(),
                };
                await createOrder(
                    { id, data: order },
                    {
                        onSuccess: () => {
                            shoppingCartItems?.map(async (cartItem) => {
                                await deleteShoppingCartItem(cartItem.id);
                            });
                            queryClient.invalidateQueries(
                                FIRESTORE_PATH.SHOPPING_CART,
                            );
                        },
                    },
                );
                onClose();
                toast({
                    title: 'Uw bestelling werd succesvol verstuurd.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onChangeLoadingState(false);
            } catch (error) {
                onChangeLoadingState(false);
                toast({
                    title: 'Oeps, er liep iets fout. Probeer het later opnieuw.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                    <Text pb="10px">Overzicht bestelling</Text>
                    <Divider variant="vPlexDivider" />
                </DrawerHeader>
                <DrawerBody>
                    {shoppingCartItems?.map((item, index) => {
                        return (
                            <Box key={`${item.reference}-${index}`}>
                                <Flex direction="column" marginBottom="20px">
                                    <Image
                                        boxSize="220px"
                                        objectFit="cover"
                                        src={item.image}
                                    />
                                    <FormControl marginTop="20px">
                                        <Flex align="center">
                                            <FormLabel marginBottom="0">
                                                Aantal:
                                            </FormLabel>
                                            <Input
                                                defaultValue={item.amount}
                                                min={1}
                                                width="155px"
                                                size="sm"
                                                type="number"
                                                onChange={(e) =>
                                                    onChangeAmount(
                                                        parseInt(
                                                            e.currentTarget
                                                                .value,
                                                        ),
                                                        item as ShoppingCartDto,
                                                    )
                                                }
                                            />
                                            <IconButton
                                                onClick={() =>
                                                    onDeleteItem(
                                                        item as ShoppingCartDto,
                                                    )
                                                }
                                                variant="outline"
                                                border="none"
                                                aria-label="open menu"
                                                icon={<FiTrash />}
                                                _hover={{ background: 'white' }}
                                            />
                                            {/* <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </Input> */}
                                        </Flex>
                                    </FormControl>
                                </Flex>
                                <Divider />
                            </Box>
                        );
                    })}
                    {!shoppingCartItems?.length && (
                        <Tag>Bestelling overzicht is nog leeg</Tag>
                    )}
                </DrawerBody>

                <DrawerFooter>
                    <Button variant="secondary" mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!shoppingCartItems?.length}
                        variant="primary"
                        onClick={onSubmitOrder}
                    >
                        Bestelling versturen
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};
