import { extendTheme } from "@chakra-ui/react";
import { HeadingStyling } from "../styles/heading.style";
import { DividerStyling } from "../styles/divider.style";
import { TextareaStyling } from "../styles/textarea.style";
import { InputStyling } from "../styles/input.style";
import { ButtonStyling } from "../styles/button.style";
import { RadioStyling } from "../styles/radio.style";
import { SelectStyling } from "../styles/select.style";
import { LinkStyling } from "../styles/Link.style";
import { TextStyling } from "../styles/text.style";
import { IconButtonStyling } from "./IconButton.style";
import { CheckboxStyling } from "./checkbox.style";


interface Colors {
  black: string;
  blackHover: string;
  black_5: string;
  cyanBlue: string;
  smokeWhite: string;
  errorRed: string;
  plexiColor: string;
}

export const extraChakraColors: Colors = {
  black: "#222",
  blackHover: "#101010",
  black_5: "rgba(34,34,34,.5)",
  cyanBlue: "#00b6c6",
  smokeWhite: "#ecf0f1",
  errorRed: "#e74c3c",
  plexiColor: "#74b9ff",
};

export const chakraTheme = extendTheme({
  fonts: {
    heading: "Montserrat, sans-serif",
    body: "Open Sans",
  },
  colors: extraChakraColors,
  components: {
    Text: TextStyling,
    Button: ButtonStyling,
    Input: InputStyling,
    Textarea: TextareaStyling,
    Heading: HeadingStyling,
    Divider: DividerStyling,
    Radio: RadioStyling,
    Select: SelectStyling,
    Link: LinkStyling,
    IconButton: IconButtonStyling,
    Checkbox: CheckboxStyling
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.2rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
});
