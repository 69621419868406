import React, { useEffect, useState } from 'react'
import { AiOutlineRotateLeft, AiOutlineRotateRight } from 'react-icons/ai';

import {
    Flex,
    Text,
    RadioGroup,
    Radio,
    Stack,
    Icon
} from '@chakra-ui/react';

interface IProps {
    isSubmitSuccessful: boolean;
    onChangeImageFilter: (imageFilter: string) => void;
    onChangeRotation: (direction: string) => void;
}

export const ImageStyles: React.FC<IProps> = ({ isSubmitSuccessful, onChangeImageFilter, onChangeRotation }) => {

    const [imageFilter, setImageFilter] = useState<string>('-1');

    useEffect(() => {
        if (isSubmitSuccessful) {
            setImageFilter('-1');
        }
    }, [isSubmitSuccessful]);

    const onChangeFilter = (value: string) => {
        setImageFilter(value);
        onChangeImageFilter(value);
    };

    return <Flex
        justifyContent="space-between"
        alignItems="center"
        border="1px solid lightGrey"
        p="5px"
        mt="5px"
    >
        <Flex direction="column" wrap="nowrap">
            <Text pr="10px">Foto filter:</Text>
            <RadioGroup
                defaultValue={ imageFilter }
                onChange={ onChangeFilter }
                value={ imageFilter }
            >
                <Stack spacing={ 1 } direction="column">
                    <Radio value="">
                        Geen filter
                    </Radio>
                    <Radio value="sepia">
                        Sepia
                    </Radio>
                    <Radio value="greyscale">
                        Zwart/wit
                    </Radio>
                </Stack>
            </RadioGroup>
        </Flex>
        <Flex>
            <Icon
                cursor="pointer"
                fontSize="3xl"
                as={ AiOutlineRotateLeft }
                onClick={ () => onChangeRotation('left') }
                mr="10px"
            />
            <Icon
                cursor="pointer"
                fontSize="3xl"
                as={ AiOutlineRotateRight }
                onClick={ () => onChangeRotation('right') }
            />
        </Flex>
    </Flex>
}
