import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
import { routes } from '../common';
import {
    Center,
    FormControl,
    Input,
    Stack,
    Button,
    FormErrorMessage,
    useToast,
} from '@chakra-ui/react';
import { doPasswordReset, getFirebaseErrorMessage } from '../firebase';
import { FirebaseError } from '@firebase/util';
import { Card } from '../components';

interface FormData {
    email: string;
}

export const ResetPasswordPage: React.FC = () => {
    const history = useHistory();
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();
    const toast = useToast();

    const onSubmit = handleSubmit(async (data: FormData) => {
        let { email } = data;
        try {
            await doPasswordReset(email);
            history.push(routes.login);

            toast({
                title: 'Aanvraag succesvol verstuurd',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: getFirebaseErrorMessage((error as FirebaseError).code),
                duration: 5000,
                status: 'error',
                isClosable: true,
            });
        }
    });

    return (
        <Center bgPos={'center'}>
            <Card header={'Wachtwoord herstellen'}>
                <form
                    style={{ width: '100%', maxWidth: '100%' }}
                    onSubmit={onSubmit}
                >
                    <FormControl
                        mb="2"
                        isRequired
                        isInvalid={!!errors?.email?.message}
                    >
                        <Input
                            {...register('email')}
                            type="email"
                            isfullwidth="true"
                            placeholder={'E-mailadres'}
                            p={2}
                        />
                        <FormErrorMessage>
                            {errors.email?.message}
                        </FormErrorMessage>
                    </FormControl>
                    <Stack pt={10}>
                        <Button type="submit" variant="primary">
                            Reset wachtwoord
                        </Button>
                        <Stack
                            direction={{ base: 'column', sm: 'row' }}
                            align={'center'}
                            justify={'center'}
                            mt={1}
                        >
                            <SmLink to={routes.login}>Terug naar login</SmLink>
                        </Stack>
                    </Stack>
                </form>
            </Card>
        </Center>
    );
};

const SmLink = styled(Link)`
    color: rgba(34, 34, 34, 0.5);
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.5s ease;

    &:hover {
        color: #222;
    }
`;
