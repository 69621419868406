import React, { useRef } from 'react'
import styled from 'styled-components';

import { Button, Icon } from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi';

interface IProps {
    label: string;
    onFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const HiddenUploader = styled.input`
    display: none;
`;
export const FileUpload: React.FC<IProps> = ({ label, onFileUpload }) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        hiddenFileInput?.current?.click();
    }

    return <>
        <HiddenUploader type="file" ref={ hiddenFileInput } onChange={ onFileUpload } />
        <Button
            mr="5px"
            w="100%"
            variant="primary"
            rightIcon={ <Icon as={ FiUpload } /> }
            onClick={ handleClick }
        >{ label }
        </Button>
    </>
}