import { OrderByDirection } from '@firebase/firestore';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { createOrder, CreateOrderEntry, getAllOrders, getAllOrdersSortedBy, getOrderById, getSortedOrdersByUserId, updateOrder, UpdateOrderEntry } from "..";
import { OrderDto } from '../../common/dto';
import { FIRESTORE_PATH } from '../../common/enums';


export const useGetOrders = (): UseQueryResult<OrderDto[]> => {
    return useQuery([FIRESTORE_PATH.ORDERS], getAllOrders);
}

export const useGetSortedOrdersByUserId = ( sortProperty: string, sortDirection: OrderByDirection, filterProperty: string, userId: string | undefined): UseQueryResult<OrderDto[]> => {
    return useQuery(FIRESTORE_PATH.ORDERS, () => getSortedOrdersByUserId(sortProperty, sortDirection, filterProperty, userId));
}

export const useGetOrderById = (id: string): UseQueryResult<OrderDto> => {
    return useQuery([FIRESTORE_PATH.ORDERS, id], () => getOrderById(id));
}

export const useGetOrdersSortedBy = (property: string, direction: 'asc' | 'desc', isAdmin: boolean | undefined): UseQueryResult<OrderDto[]> => {    
    return useQuery([FIRESTORE_PATH.ORDERS], () => getAllOrdersSortedBy(property, direction), {enabled: isAdmin});
}

export const useCreateOrder = (): UseMutationResult<void, unknown, CreateOrderEntry> => {
    return useMutation(createOrder);
}

export const useUpdateOrder = (): UseMutationResult<void, unknown, UpdateOrderEntry> => {
    return useMutation(updateOrder);
}