import { deleteDoc, getDocs, setDoc, updateDoc } from "@firebase/firestore"
import { CreateShoppingCartEntry, UpdateShoppingCartEntry } from '..';
import { FIRESTORE_PATH } from "../../common/enums";
import { firestoreDocWithPathSegments, firestoreQueryCollectionWhere, shoppingCartDocs } from "../../firebase"

export const getAllShoppingCartItems = async () => {
    const snapshot = await shoppingCartDocs();

    return snapshot.docs.map((doc) => doc.data());
}

export const getShoppingCartItemsByUserId = async (userId: string | undefined) => {
    const query = firestoreQueryCollectionWhere(FIRESTORE_PATH.SHOPPING_CART, "userId", userId)
    const snapshot = await getDocs(query);

    return snapshot.docs.map((doc) => doc.data());
}

export const createShoppingCartItem = async ({ id, data }: CreateShoppingCartEntry) => {
    await setDoc(firestoreDocWithPathSegments(FIRESTORE_PATH.SHOPPING_CART, id), data);
}

export const updateShoppingCartItem = async ({ id, data }: UpdateShoppingCartEntry) => {
    const shoppingCartItemRef = firestoreDocWithPathSegments(FIRESTORE_PATH.SHOPPING_CART, id);
    await updateDoc(shoppingCartItemRef, data)
}

export const deleteShoppingCartItem = async (id: string) => {
    const shoppingCartItemRef = firestoreDocWithPathSegments(FIRESTORE_PATH.SHOPPING_CART, id);
    await deleteDoc(shoppingCartItemRef);
}