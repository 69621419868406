const USER_NOT_FOUND = "Deze gebruiker werd niet teruggevonden.";
const EMAIL_ALREADY_EXIST = "Dit e-mailadres is reeds in gebruikt.";
const INTERNAL_ERROR = "Oeps, er liep iets fout. Probeer het later opnieuw.";
const INVALID_CREDENTIAL = "E-mailadres en wachtwoord komen niet overeen.";
const INVALID_EMAIL_FORMAT = "Het e-mailadres is geen geldig formaat.";
const INVALID_PASSWORD_FORMAT = "Het wachtwoord is geen geldig formaat.";
const TO_MANY_ATTEMPTS = "De toegang tot dit account is tijdelijk geblokkeerd door te veel inlogpogingen."
const DEFAULT_MESSAGE = "Oeps, er liep iets fout. Probeer het later opnieuw.";

export const ERROR_MESSAGES = {
    USER_NOT_FOUND,
    EMAIL_ALREADY_EXIST,
    INTERNAL_ERROR,
    INVALID_CREDENTIAL,
    INVALID_EMAIL_FORMAT,
    INVALID_PASSWORD_FORMAT,
    DEFAULT_MESSAGE,
    TO_MANY_ATTEMPTS
};