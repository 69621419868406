import React from 'react';
import { Layout } from '../components';
import {
    Accordion,
    AccordionPanel,
    AccordionItem,
    AccordionIcon,
    AccordionButton,
    Box,
    useDisclosure,
    Heading,
    Divider,
    Text,
    Flex,
} from '@chakra-ui/react';

interface FAQItem {
    title: string;
    description: string;
}

const FAQItems: FAQItem[] = [
    {
        title: 'Hoe lang duurt het voor mijn bestelling klaar is?',
        description:
            'Donec sed odio dui. Nullam id dolor id nibh ultricies vehicula ut id elit.' +
            'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Morbi leo risus, porta ac consectetur ac,' +
            'vestibulum at eros. Aenean lacinia bibendum nulla sed consectetur.',
    },
    {
        title: 'Krijg ik bevestiging als mijn bestelling goed is ontvangen?',
        description:
            'Donec sed odio dui. Nullam id dolor id nibh ultricies vehicula ut id elit.' +
            'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Morbi leo risus, porta ac consectetur ac,' +
            'vestibulum at eros. Aenean lacinia bibendum nulla sed consectetur.',
    },
    {
        title: 'Kan ik de status van mijn bestelling opvolgen?',
        description:
            'Donec sed odio dui. Nullam id dolor id nibh ultricies vehicula ut id elit.' +
            'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Morbi leo risus, porta ac consectetur ac,' +
            'vestibulum at eros. Aenean lacinia bibendum nulla sed consectetur.',
    },
    {
        title: 'Wat zijn de verzendkosten?',
        description:
            'Donec sed odio dui. Nullam id dolor id nibh ultricies vehicula ut id elit.' +
            'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Morbi leo risus, porta ac consectetur ac,' +
            'vestibulum at eros. Aenean lacinia bibendum nulla sed consectetur.',
    },
];

export const FaqPage: React.FC = () => {
    const { onOpen } = useDisclosure();

    const onToggleDrawer = () => onOpen();
    return (
        <Layout onToggleDrawer={onToggleDrawer}>
            <Flex direction="column" alignItems="center" marginBottom="50px">
                <Heading alignSelf="center" as="h1">
                    Veelgestelde vragen
                </Heading>
                <Divider variant="vPlexDivider" />
                <Text align="center" maxWidth="60%">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Sapiente ex, ab et necessitatibus quaerat voluptatibus
                    mollitia sunt aliquam, eos, vel autem distinctio consequatur
                    blanditiis repellendus voluptate voluptatum culpa quod
                    saepe!
                </Text>
            </Flex>
            <Accordion allowToggle allowMultiple>
                {FAQItems.map((faqItem, idx) => (
                    <AccordionItem key={`${faqItem.title}-${idx}`}>
                        <h2>
                            <AccordionButton padding="10px">
                                <Box flex="1" textAlign="left">
                                    {faqItem.title}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            {faqItem.description}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Layout>
    );
};
