import React, { useEffect, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import styled from 'styled-components';
import { FileUpload } from './file-upload.component';
import { FiCrop } from 'react-icons/fi';
import { Flex, Button, Icon } from '@chakra-ui/react';

interface IProps {
    image: string | undefined;
    onCrop: (data: any) => void;
    onChangeCropper: (cropper: Cropper | undefined) => void;
    onChangeFileUpload: (fileList: FileList) => void;
}

export const ImageCropper: React.FC<IProps> = ({
    image,
    onCrop,
    onChangeCropper,
    onChangeFileUpload,
}) => {
    const [cropper, setCropper] = useState<Cropper | undefined>();

    const getCropData = () => {
        if (typeof cropper !== 'undefined' && onCrop !== undefined) {
            onCrop(cropper);
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        const fileList = event.target.files;
        if (fileList) onChangeFileUpload(fileList);
    };

    useEffect(() => {
        onChangeCropper(cropper);
    }, [cropper, onChangeCropper]);

    return (
        <>
            <CropperWrapper
                initialAspectRatio={1 / 1.6}
                aspectRatio={1 / 1.6}
                src={image}
                dragMode="move"
                guides
                minCropBoxHeight={51}
                minCropBoxWidth={51}
                background={false}
                responsive={true}
                checkOrientation={false}
                rotatable
                onInitialized={(instance) => {
                    setCropper(instance);
                }}
            />
            <Flex justifyContent="space-around">
                <FileUpload
                    onFileUpload={handleFileUpload}
                    label="Foto uploaden"
                />
                <Button
                    ml="5px"
                    w="100%"
                    variant="secondary"
                    onClick={getCropData}
                    rightIcon={<Icon as={FiCrop} />}
                >
                    Foto bijknippen
                </Button>
            </Flex>
        </>
    );
};

const CropperWrapper = styled(Cropper)`
    max-height: 510px;
    min-height: 510px;
    max-width: 510px;
    min-width: 510px;
    margin-bottom: 10px;

    .cropper-container {
        height: 100%;
    }
`;
