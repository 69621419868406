import React, { useRef } from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';

interface IProps {
    firstName: string;
    lastName: string;
    message: string;
    textAlignment: 'left' | 'center' | 'right';
    fontFamily: string;
    fontSize: number;
    isFontBoldMsg: boolean;
    isFontBoldName: boolean;
    isFontItalic: boolean;
    cropData: string | undefined;
    imageFilter: string;
    ashesSide: string;
}

const Outer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 800px;
    height: 800px;
    background-color: #74b9ff;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const AshesContainer = styled.div`
    position: relative;
    overflow: auto;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 15px;
`;

const AshTube = styled.div`
    position: relative;
    width: 30px;
    height: 350px;
    background-color: #222;

    &:before {
        position: absolute;
        content: '';
        left: calc(50% - 30px);
        top: 0;
        width: 60px;
        height: 30px;
        background-color: #222;
    }

    &:after {
        position: absolute;
        content: '';
        left: calc(50% - 30px);
        bottom: 0;
        width: 60px;
        height: 30px;
        background-color: #222;
    }
`;

const TextContainer = styled.div`
    box-sizing: border-box;
    position: relative;
    height: 20%;
    align-self: flex-end;
    width: 100%;
    max-width: 233px;
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: 1px solid rgba(34, 34, 34, 0.2);
`;

const Inner = styled.div`
    position: relative;
    width: 700px;
    height: 700px;
    background-color: #fff;
    display: flex;
    flex-direction: row;

    & .dragBox {
        display: inline-block;
        cursor: grab;

        &:focus,
        &:active {
            cursor: grabbing;
        }
    }

    &.ashes-R {
        flex-direction: row-reverse;

        ${AshesContainer}, ${TextContainer} {
            border-right: 0;
            border-left: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
`;

const DragBox = styled.div<{ textAlignment: string; fontFamily: string }>`
    display: inline-block;
    text-align: ${({ textAlignment }) => textAlignment};
    font-family: ${({ fontFamily }) => `${fontFamily}`};
    max-width: 233px;
`;

const Handle = styled.div<{ isFontItalic: boolean }>`
    display: flex;
    flex-direction: column;
    font-style: ${({ isFontItalic }) => (isFontItalic ? 'italic' : 'normal')};
    cursor: grab;

    &:focus,
    &:active {
        cursor: grabbing;
    }
`;

const ImageContainer = styled.div`
    height: 100%;
    flex: 2;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    & img {
        max-width: 100%;
        height: auto;

        &.greyscale {
            filter: grayscale(100%);
        }

        &.sepia {
            filter: sepia(100%);
        }
    }
`;

const Name = styled.span<{ isFontBold: boolean; fontSize: number }>`
    display: inline-block;
    max-width: 233px;
    margin-bottom: 5px;
    font-weight: ${({ isFontBold }) => (isFontBold ? 700 : 400)};
    font-size: ${({ fontSize }) => `${fontSize}px`};
`;
const Message = styled.span<{ isFontBold: boolean; fontSize: number }>`
    display: inline-block;
    max-width: 233px;
    font-weight: ${({ isFontBold }) => (isFontBold ? 700 : 400)};
    font-size: ${({ fontSize }) => `${fontSize}px`};
    white-space: pre-wrap;
`;

export const MockResult: React.FC<IProps> = ({
    textAlignment = 'center',
    ashesSide = 'L',
    fontFamily,
    cropData,
    isFontBoldMsg,
    isFontBoldName,
    isFontItalic,
    firstName,
    lastName,
    message,
    fontSize,
    imageFilter,
}) => {
    const nodeRef_01 = useRef(null);
    const nodeRef_02 = useRef(null);

    return (
        <>
            <Outer>
                <Inner id="mock-result" className={`ashes-${ashesSide}`}>
                    <LeftContainer>
                        <AshesContainer>
                            <Draggable bounds="parent" nodeRef={nodeRef_02}>
                                <div className="dragBox" ref={nodeRef_02}>
                                    <div className="handle">
                                        <AshTube />
                                    </div>
                                </div>
                            </Draggable>
                        </AshesContainer>
                        <TextContainer>
                            <Draggable bounds="parent" nodeRef={nodeRef_01}>
                                <DragBox
                                    textAlignment={textAlignment}
                                    fontFamily={fontFamily}
                                    className="dragBox"
                                    ref={nodeRef_01}
                                >
                                    <Handle
                                        className="handle"
                                        isFontItalic={isFontItalic}
                                    >
                                        <Name
                                            isFontBold={isFontBoldName}
                                            fontSize={fontSize}
                                        >
                                            {firstName} {lastName}
                                        </Name>
                                        <Message
                                            isFontBold={isFontBoldMsg}
                                            fontSize={fontSize}
                                        >
                                            {message}
                                        </Message>
                                    </Handle>
                                </DragBox>
                            </Draggable>
                        </TextContainer>
                    </LeftContainer>
                    <ImageContainer>
                        {cropData && (
                            <img
                                className={imageFilter}
                                src={cropData}
                                alt="cropped"
                            />
                        )}
                    </ImageContainer>
                </Inner>
            </Outer>
        </>
    );
};
