export const CheckboxStyling = {
    baseStyle: {
      outline: "none",
      control: {
        _checked: {
          bg: "black",
          border: "1px solid black",
          boxShadow: 'none !important',
        },
        _focus: {
          border: "1px solid inherit",
          boxShadow: 'none !important',
        },
      }
    }
  }