import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Divider, Heading, Badge, useDisclosure } from '@chakra-ui/react';
import { Layout, VTable, ShoppingCartDrawer } from '../components';
import { useGetOrdersSortedBy } from '../orders';
import { useHistory } from 'react-router';
import { OrderDto } from '../common/dto';
import { Row } from 'react-table';
import { getStatusBadge, routes, useAuth } from '../common';
import { format } from 'date-fns';

interface CellProps {
    cell: {
        value: string | number;
    };
}

export const OrdersOverviewPage: React.FC = () => {
    const { onClose, onOpen, isOpen } = useDisclosure();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const history = useHistory();
    const { authenticatedUser } = useAuth();

    const { data: orders, isLoading: isFetching } = useGetOrdersSortedBy(
        'creationDate',
        'desc',
        authenticatedUser?.isAdmin,
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'E-mail',
                accessor: 'email',
            },
            {
                Header: 'Bedrijf',
                accessor: 'company',
            },
            {
                Header: 'Datum - Tijd',
                accessor: 'creationDate',
                Cell: (cellInfo: CellProps) =>
                    format(cellInfo.cell.value as number, 'dd/MM/yyyy HH:mm'),
            },
            {
                Header: '# ontwerpen',
                accessor: 'items.length',
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (cellInfo: CellProps) =>
                    getStatusBadge(cellInfo.cell.value as string),
            },
        ],
        [],
    );

    const handleClickRow = (row: Row<object>) => {
        history.push(`/orders/${(row as Row<OrderDto>).original.id}`);
    };

    if (!authenticatedUser?.isAdmin) {
        return <Redirect to={routes.designer} />;
    }

    return (
        <Layout isLoading={isFetching || isLoading} onToggleDrawer={onOpen}>
            <ShoppingCartDrawer
                isOpen={isOpen}
                onChangeLoadingState={setIsLoading}
                onOpen={onOpen}
                onClose={onClose}
            />
            <Heading as="h1">Bestellingsoverzicht</Heading>
            <Divider variant="vPlexDivider" />
            {orders?.length ? (
                <VTable
                    data={orders}
                    columns={columns}
                    onClickRow={handleClickRow}
                />
            ) : (
                <Badge>Geen bestellingen gevonden.</Badge>
            )}
        </Layout>
    );
};
