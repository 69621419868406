import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    updatePassword,
    signOut,
    UserCredential
} from "firebase/auth";
import { auth } from ".";
import { SignInData } from "../common";

// Sign Up
export const doCreateUserWithEmailAndPassword = async ({ email, password }: SignInData): Promise<UserCredential> => {
    try {
        return await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
        throw error;
    }
}

// Sign In
export const doSignInWithEmailAndPassword = async ({ email, password }: SignInData) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
        throw error
    }
}

// Get current User
export const getCurrentUser = () => {
    if (auth.currentUser) {
        return auth.currentUser;
    }
};

// Sign out
export const doSignOut = async () => {
    try {
        await signOut(auth);
    } catch (error) {
        throw error;
    }
}

// Password Reset
export const doPasswordReset = async (email: string) => {
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (error) {
        throw error;
    }
}

// Password Change
export const doPasswordUpdate = async (password: string) => {
    if (auth.currentUser) {
        await updatePassword(auth.currentUser, password);
    }
    throw Error('No auth.currentUser!');
};