export const InputStyling = {
  baseStyle: {
    field: {
      color: "gray.700",
      _disabled: {
        cursor: 'not-allowed'
      }
    }
  },
  variants: {
    flushed: {
      field: {
        _focus: {
          borderColor: 'black_5',
          boxShadow: 'none',
        },
      },
    },
  },
  defaultProps: {
    size: "sm",
    variant: 'flushed',
  },
};
