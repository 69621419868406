// TODO: Move to a CONSTANT file instead. This does not belong here.

export const routes = {
  login: "/login",
  dashboard: "/dashboard",
  admin_dashboard: "/admin-dashboard",
  designer: "/ontwerp",
  resetPassword: "/reset-password",
  register: "/register",
  orders: "/orders",
  users: "/users",
  components: "/components",
  faq: "/faq"
};
