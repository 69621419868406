import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Select, Icon, IconButton, Button } from '@chakra-ui/react';
import {
    FiAlignCenter,
    FiAlignLeft,
    FiAlignRight,
    FiBold,
    FiItalic,
} from 'react-icons/fi';

type TextAlignment = 'left' | 'right' | 'center';
interface IProps {
    onChangeTextAlignment: (textAlignment: TextAlignment) => void;
    onChangeFontWeight: (item: string) => void;
    onChangeFontStyle: (isItalic: boolean) => void;
    onChangeFontFamily: (fontFamily: string) => void;
    onChangeFontSize: (fontSize: number) => void;
    isSubmitSuccessful: boolean;
}

export const TextStyles: React.FC<IProps> = ({
    onChangeFontFamily,
    onChangeFontStyle,
    onChangeFontWeight,
    onChangeTextAlignment,
    onChangeFontSize,
    isSubmitSuccessful = false,
}) => {
    const [fontFamily, setFontFamily] = useState<string>('Open Sans');
    const [fontSize, setFontSize] = useState<number>(16);
    const [textAlignment, setTextAlignment] = useState<TextAlignment>('left');
    const [isFullnameBold, setIsFullnameBold] = useState<boolean>();
    const [isMessageBold, setIsMessageBold] = useState<boolean>();
    const [isItalic, setIsItalic] = useState<boolean>(false);

    useEffect(() => {
        if (isSubmitSuccessful) {
            setFontFamily('Open Sans');
            setFontSize(16);
            setTextAlignment('left');
            setIsFullnameBold(false);
            setIsMessageBold(false);
            setIsItalic(false);
        }
    }, [isSubmitSuccessful]);

    const handleChangeFontFamily = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const fontFamily = e.target.value;
            setFontFamily(fontFamily);
            onChangeFontFamily(fontFamily);
        },
        [onChangeFontFamily],
    );

    const handleChangeFontSize = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const fontSize = e.target.value;
            setFontSize(parseInt(fontSize, 10));
            onChangeFontSize(parseInt(fontSize, 10));
        },
        [onChangeFontSize],
    );

    const handleTextAlignmentClick = useCallback(
        (textAlignment: TextAlignment) => {
            setTextAlignment(textAlignment);
            onChangeTextAlignment(textAlignment);
        },
        [onChangeTextAlignment],
    );

    const handleFontStyleClick = useCallback(() => {
        setIsItalic((isItalic) => !isItalic);
        onChangeFontStyle(isItalic);
    }, [isItalic, onChangeFontStyle]);

    const handleFontWeightClick = useCallback(
        (item: 'message' | 'fullname') => {
            item === 'message'
                ? setIsMessageBold((prevState) => !prevState)
                : setIsFullnameBold((prevState) => !prevState);
            onChangeFontWeight(item);
        },
        [onChangeFontWeight],
    );

    return (
        <Flex
            alignItems={'center'}
            w="510px"
            minH="50px"
            border="1px"
            borderColor="gray.200"
            px="10px"
            py="5px"
            mb="10px"
        >
            <Select
                value={fontFamily}
                onChange={handleChangeFontFamily}
                w={120}
                mr="5px"
            >
                <option disabled>Lettertype</option>
                <option value="sans-serif">Sans-serif</option>
                <option value="serif">Serif</option>
                <option value="handscript">Handscript</option>
            </Select>
            <Select
                value={fontSize}
                onChange={handleChangeFontSize}
                w={75}
                mr="5px"
            >
                <option value={16}>16pt</option>
                <option value={18}>18pt</option>
                <option value={20}>20pt</option>
                <option value={24}>24pt</option>
            </Select>
            <IconButton
                icon={<Icon as={FiAlignLeft} />}
                onClick={() => handleTextAlignmentClick('left')}
                isActive={textAlignment === 'left'}
                aria-label="align left"
                colorScheme={'blackAlpha'}
                borderRight="1px solid white"
            ></IconButton>
            <IconButton
                icon={<Icon as={FiAlignCenter} />}
                onClick={() => handleTextAlignmentClick('center')}
                isActive={textAlignment === 'center'}
                aria-label="align center"
                colorScheme={'blackAlpha'}
                borderRight="1px solid white"
            ></IconButton>
            <IconButton
                icon={<Icon as={FiAlignRight} />}
                onClick={() => handleTextAlignmentClick('right')}
                isActive={textAlignment === 'right'}
                aria-label="align right"
                colorScheme={'blackAlpha'}
                borderRight="1px solid white"
            ></IconButton>
            <IconButton
                icon={<Icon as={FiItalic} />}
                onClick={handleFontStyleClick}
                isActive={isItalic}
                aria-label="italic"
                colorScheme={'blackAlpha'}
            />
            <Flex flexDirection="column" ml="5px">
                <Button
                    leftIcon={<Icon as={FiBold} />}
                    colorScheme="teal"
                    variant="flat"
                    onClick={() => handleFontWeightClick('fullname')}
                    justifyContent="flex-start"
                    isActive={isFullnameBold}
                    mb="1px"
                >
                    Naam/voornaam
                </Button>
                <Button
                    leftIcon={<Icon as={FiBold} />}
                    colorScheme="teal"
                    variant="flat"
                    onClick={() => handleFontWeightClick('message')}
                    justifyContent="flex-start"
                    isActive={isMessageBold}
                >
                    Boodschap
                </Button>
            </Flex>
        </Flex>
    );
};
