export const DividerStyling = {
  baseStyle: {
    marginBottom: "5"
  },
  variants: {
    vPlexDivider: {
      opacity: "1",
      borderBottomWidth: "2px !important",
      borderBottomStyle: "solid !important",
      borderBottomColor: "black !important",
      maxWidth: "30px",
    },
  }
};
