import { useMutation, UseMutationResult, useQuery } from 'react-query';
import { CreateShoppingCartEntry, UpdateShoppingCartEntry } from '..';
import { FIRESTORE_PATH } from '../../common/enums';
import {
    createShoppingCartItem,
    getAllShoppingCartItems,
    getShoppingCartItemsByUserId,
    updateShoppingCartItem,
    deleteShoppingCartItem
} from '../db/shopping-cart.db';

export const useGetShoppingCartItems = () => {
    return useQuery(FIRESTORE_PATH.SHOPPING_CART, getAllShoppingCartItems)
}


export const useGetShoppingCartItemsByUserId = (userId: string | undefined) => {
    return useQuery(FIRESTORE_PATH.SHOPPING_CART, () => getShoppingCartItemsByUserId(userId))
}

export const useCreateShoppingCartItem = (): UseMutationResult<void, unknown, CreateShoppingCartEntry> => {
    return useMutation(createShoppingCartItem);
}

export const useUpdateShoppingCartItem = (): UseMutationResult<void, unknown, UpdateShoppingCartEntry> => {
    return useMutation(updateShoppingCartItem);
}

export const useDeleteShoppingCartItem = (): UseMutationResult<void, unknown, string> => {
    return useMutation(deleteShoppingCartItem);
}