import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, query, where, orderBy, OrderByDirection } from 'firebase/firestore'
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { FIRESTORE_PATH } from '../common/enums';

const config = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
// Init the app
const app = initializeApp(config);

// get the DB instance
export const db = getFirestore(app);
// get the storage instance
// TODO: Add reCAPTCHA v3 & setup billing account
export const storage = getStorage(app)
export const auth = getAuth(app);


/***** HELPERS *****/
const firestoreCollection = (path: string) => collection(db, path);
export const firestoreQueryCollectionWhere = (path: string, property: string, value: string | undefined | unknown) => query(collection(db, path), where(property, '==', value))
export const firestoreQueryCollectionOrderBy = (path: string, property: string, direction: OrderByDirection) => query(collection(db, path), orderBy(property, direction));
export const firestoreQueryCollectionOrderByWhere = (path: string, sortProperty: string, sortDirection: OrderByDirection, filterProperty: string, filterValue: string | undefined | unknown) => query(collection(db, path), where(filterProperty, '==', filterValue), orderBy(sortProperty, sortDirection));

export const firestoreDoc = (path: string) => doc(db, path);
export const firestoreDocWithPathSegments = (path: string, documentId: string) => doc(db, path, documentId);

/***** ORDERS *****/
export const ordersCollection = firestoreCollection(FIRESTORE_PATH.ORDERS);
export const orderDocs = async () => getDocs(ordersCollection);

/***** SHOPPING CART *****/
export const shoppingCartCollection = firestoreCollection(FIRESTORE_PATH.SHOPPING_CART);
export const shoppingCartDocs = async () => getDocs(shoppingCartCollection);

/***** USERS *****/
export const usersCollection = firestoreCollection(FIRESTORE_PATH.USERS);
export const userDocs = async () => getDocs(usersCollection);


