import { ShoppingCartDto } from ".";

export enum Status {
    pending ='In afwachting', 
    processing = 'In behandeling', 
    ready = 'Klaar voor afhaling', 
    completed='Afgerond'
};
export interface OrderDto {
    userId: string;
    id: string;
    company: string;
    email: string;
    items: ShoppingCartDto[];
    status: Status;
    creationDate: number;
}