import { BrowserRouter } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router';
import {
    DesignPage,
    FaqPage,
    LoginPage,
    UserDetailPage,
    ResetPasswordPage,
    OrdersOverviewPage,
    OrderDetailPage,
    UsersOverviewPage,
    DashboardPage,
    AdminDashboardPage,
} from './pages';
import { AuthenticationProvider, routes } from './common';
import { PrivateRoute } from './components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ChakraProvider } from '@chakra-ui/react';
import { chakraTheme } from './common/styles';

const queryClient = new QueryClient();

function App() {
    return (
        <AuthenticationProvider>
            <QueryClientProvider client={queryClient}>
                <ChakraProvider theme={chakraTheme} resetCSS>
                    <BrowserRouter>
                        <Switch>
                            <PrivateRoute path={routes.admin_dashboard}>
                                <AdminDashboardPage />
                            </PrivateRoute>
                            <PrivateRoute path={routes.dashboard}>
                                <DashboardPage />
                            </PrivateRoute>
                            <PrivateRoute path={routes.designer}>
                                <DesignPage />
                            </PrivateRoute>
                            <PrivateRoute exact path={`${routes.orders}/:id`}>
                                <OrderDetailPage />
                            </PrivateRoute>
                            <PrivateRoute exact path={routes.orders}>
                                <OrdersOverviewPage />
                            </PrivateRoute>
                            <PrivateRoute exact path={`${routes.users}/:id`}>
                                <UserDetailPage />
                            </PrivateRoute>
                            <PrivateRoute path={routes.users}>
                                <UsersOverviewPage />
                            </PrivateRoute>
                            <PrivateRoute path={routes.register}>
                                <UserDetailPage />
                            </PrivateRoute>
                            <PrivateRoute path={routes.faq}>
                                <FaqPage />
                            </PrivateRoute>
                            <Route path={routes.login}>
                                <LoginPage />
                            </Route>
                            <Route path={routes.resetPassword}>
                                <ResetPasswordPage />
                            </Route>
                            <Redirect from="/" to={routes.login} />
                        </Switch>
                    </BrowserRouter>
                </ChakraProvider>
            </QueryClientProvider>
        </AuthenticationProvider>
    );
}

export default App;
