export enum Shape {
    square = 'Vierkant',
    rectangle = 'Rechthoek',
    triangle = 'Driehoek',
    disk = 'Schijf'
}

export interface ShoppingCartDto {
    id: string;
    userId: string;
    image: string;
    reference: string;
    shape: Shape;
    amount: number;
}