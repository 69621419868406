import React from "react";
import { SidebarWithHeader } from "./";
import { Box } from '@chakra-ui/react'
import { LoadingSpinner } from "./loading-spinner.component";
import { useAuth } from "../common";
interface LayoutProps {
    isLoading?: boolean;
    onToggleDrawer: () => void;
}

export const Layout: React.FC<LayoutProps> = ({ isLoading = false, onToggleDrawer, children }) => {

    const { authenticatedUser } = useAuth();

    return <>
        <SidebarWithHeader user={ authenticatedUser } onToggleDrawer={ onToggleDrawer }>
            <Box padding="20px 40px">
                { isLoading && <LoadingSpinner /> }
                { children }
            </Box>
        </SidebarWithHeader>
    </>
}