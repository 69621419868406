import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ErrorMessage, TextStyles, ImageCropper } from '.';
import { ImageStyles } from './image-styles.component';
import { FiCheck } from 'react-icons/fi';
import {
    Flex,
    Divider,
    Heading,
    VStack,
    FormControl,
    Input,
    Textarea,
    Button,
    Icon,
    FormErrorMessage,
    Select,
    Text,
    RadioGroup,
    Radio,
} from '@chakra-ui/react';
import { Shape } from '../common/dto';

type FormData = {
    firstName?: string | undefined;
    lastName?: string | undefined;
    message: string;
    reference: string;
    shape: string;
};

interface IProps {
    image: string | undefined;
    onChangeCropData: (cropData: string) => void;
    onChangeTextAlignment: (textAlignment: 'left' | 'right' | 'center') => void;
    onChangeFontWeight: (type: string) => void;
    onChangeFontStyle: (isItalic: boolean) => void;
    onChangeFontSize: (fontSize: number) => void;
    onChangeFontFamily: (fontFamily: string) => void;
    onChangeLastName: (lastName: string) => void;
    onChangeFirstName: (firstName: string) => void;
    onChangeMessage: (message: string) => void;
    onChangeReference: (reference: string) => void;
    onChangeShape: (shape: Shape) => void;
    onChangeImageFilter: (imageFilter: string) => void;
    onChangeRotation: (direction: string) => void;
    onChangeFileUpload: (files: FileList) => void;
    onChangeCropper: (cropper: Cropper | undefined) => void;
    onChangeAshesSide: (side: string) => void;
    onSubmitResult: () => void;
}

const validationSchema: yup.SchemaOf<Omit<FormData, 'firstName' | 'lastName'>> =
    yup.object().shape({
        message: yup.string().required('Gelieve een boodschap op te geven.'),
        reference: yup
            .string()
            .required(
                'Gelieve een herkenbare referentie op te geven voor de bestelling.',
            ),
        shape: yup.string().required(),
    });

export const Designer: React.FC<IProps> = ({
    image,
    onChangeCropData,
    onChangeTextAlignment,
    onChangeFontFamily,
    onChangeFontStyle,
    onChangeFontWeight,
    onChangeFirstName,
    onChangeLastName,
    onChangeFontSize,
    onChangeMessage,
    onChangeReference,
    onChangeShape,
    onChangeImageFilter,
    onChangeRotation,
    onChangeFileUpload,
    onChangeCropper,
    onChangeAshesSide,
    onSubmitResult,
}) => {
    const [isInValidForm, setIsInValidForm] = useState<boolean>(false);

    const [message, setMessage] = useState<string>('');
    const [reference, setReference] = useState<string>('');
    const [cropData, setCropData] = useState<string>('');

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitSuccessful },
        reset,
    } = useForm<FormData>({
        resolver: yupResolver(validationSchema),
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        if (message.length > 0 && reference.length > 0 && cropData.length > 0) {
            setIsInValidForm(false);
        } else {
            setIsInValidForm(true);
        }
    }, [cropData.length, message.length, reference.length]);

    const onCropImage = (data: Cropper) => {
        const cropData = data
            .getCroppedCanvas({
                width: 610,
                height: 1000,
            })
            .toDataURL('PNG');
        setCropData(cropData);
        onChangeCropData(cropData);
    };

    const onChangeInput = (
        e: React.FormEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >,
    ) => {
        const currentTarget = e.currentTarget;
        switch (currentTarget.id) {
            case 'lastName':
                onChangeLastName(currentTarget.value);
                break;
            case 'firstName':
                onChangeFirstName(currentTarget.value);
                break;
            case 'message':
                setMessage(currentTarget.value);
                onChangeMessage(currentTarget.value);
                break;
            case 'reference':
                setReference(currentTarget.value);
                onChangeReference(currentTarget.value);
                break;
            case 'shape':
                onChangeShape(currentTarget.value as Shape);
                break;
        }
        return;
    };

    const onSubmit = () => {
        if (!errors.reference || !errors.message) {
            setReference('');
            setMessage('');
            setCropData('');
            reset();
            onSubmitResult();
        }
    };

    return (
        <Flex direction={'column'}>
            <Heading as="h1">Creeër uw ontwerp</Heading>
            <Divider variant="vPlexDivider" />
            <VStack>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex alignItems="center" mb="3">
                        <FormControl mr="3">
                            <Input
                                {...register('firstName')}
                                name="firstName"
                                id="firstName"
                                type="text"
                                placeholder="Voornaam"
                                onChange={onChangeInput}
                            />
                            {errors.firstName && (
                                <FormErrorMessage>
                                    {errors.firstName?.message}
                                </FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl>
                            <Input
                                {...register('lastName')}
                                name="lastName"
                                id="lastName"
                                type="text"
                                placeholder="Familienaam"
                                onChange={onChangeInput}
                            />
                            {errors.lastName && (
                                <ErrorMessage>
                                    {errors.lastName?.message}
                                </ErrorMessage>
                            )}
                        </FormControl>
                    </Flex>
                    <Flex alignItems="center" mb="3">
                        <FormControl>
                            <Textarea
                                {...register('message')}
                                id="message"
                                rows={2}
                                name="message"
                                placeholder="Boodschap / datum"
                                onChange={onChangeInput}
                            />
                            {errors.message && (
                                <ErrorMessage>
                                    {errors.message?.message}
                                </ErrorMessage>
                            )}
                        </FormControl>
                    </Flex>
                    <Flex alignItems="center" mb="3">
                        <FormControl>
                            <Input
                                {...register('reference')}
                                id="reference"
                                name="reference"
                                placeholder="Herkenbare referentie / naam overledene"
                                onChange={onChangeInput}
                            />
                            {errors.reference && (
                                <ErrorMessage>
                                    {errors.reference?.message}
                                </ErrorMessage>
                            )}
                        </FormControl>
                    </Flex>
                    <Flex justifyContent="start" alignItems="center" mb="3">
                        <FormControl
                            flex="1"
                            borderRight="1px solid"
                            borderColor="gray.300"
                            paddingRight="3"
                        >
                            <Flex alignItems="center">
                                <Text
                                    fontSize="14px"
                                    mr="1"
                                    w="60px"
                                    fontWeight={400}
                                    color="gray.400"
                                >
                                    As zijde:
                                </Text>
                                <RadioGroup
                                    defaultValue="L"
                                    variant="switchRadio"
                                    onChange={onChangeAshesSide}
                                >
                                    <Flex direction="row">
                                        <Radio colorScheme="red" value="L">
                                            Links
                                        </Radio>
                                        <Radio colorScheme="green" value="R">
                                            Rechts
                                        </Radio>
                                    </Flex>
                                </RadioGroup>
                            </Flex>
                        </FormControl>
                        <FormControl flex="2" paddingLeft={3}>
                            <Flex alignItems="center">
                                <Text
                                    fontSize="14px"
                                    mr="1"
                                    w="60px"
                                    fontWeight={400}
                                    color="gray.400"
                                >
                                    Vorm:
                                </Text>
                                <Select
                                    {...register('shape')}
                                    id="shape"
                                    onChange={onChangeInput}
                                    defaultValue={Shape.square}
                                >
                                    <option value={Shape.square}>
                                        {Shape.square}
                                    </option>
                                    <option value={Shape.triangle}>
                                        {Shape.triangle}
                                    </option>
                                    <option value={Shape.disk}>
                                        {Shape.disk}
                                    </option>
                                </Select>
                            </Flex>
                        </FormControl>
                    </Flex>
                    <TextStyles
                        onChangeTextAlignment={onChangeTextAlignment}
                        onChangeFontFamily={onChangeFontFamily}
                        onChangeFontWeight={onChangeFontWeight}
                        onChangeFontStyle={onChangeFontStyle}
                        onChangeFontSize={onChangeFontSize}
                        isSubmitSuccessful={isSubmitSuccessful}
                    />
                    <ImageCropper
                        image={image}
                        onCrop={onCropImage}
                        onChangeCropper={onChangeCropper}
                        onChangeFileUpload={onChangeFileUpload}
                    />
                    <ImageStyles
                        onChangeImageFilter={onChangeImageFilter}
                        onChangeRotation={onChangeRotation}
                        isSubmitSuccessful={isSubmitSuccessful}
                    />
                    <Button
                        rightIcon={<Icon as={FiCheck} />}
                        mt="10px"
                        width="100%"
                        variant="primary"
                        type="submit"
                        disabled={isInValidForm}
                    >
                        Creëer mijn ontwerp
                    </Button>
                </form>
            </VStack>
        </Flex>
    );
};
