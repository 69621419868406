import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { createUser, getAllUsers, getUser, updateUser } from '..';
import { UserDto } from '../../common/dto';
import { FIRESTORE_PATH } from '../../common/enums';
import { CreateUserEntry, UpdateUserEntry } from '../types/create-user-entry.type';

export const useGetUsers = (isAdmin: boolean | undefined): UseQueryResult<UserDto[]> => {
    return useQuery(FIRESTORE_PATH.USERS, getAllUsers, {enabled: isAdmin});
};

export const useGetUser = (userId: string, isAdmin: boolean | undefined): UseQueryResult<UserDto> => {
    return useQuery([FIRESTORE_PATH.USERS, userId], () => getUser(userId), {
        enabled: !!userId && isAdmin
    });
}

export const useCreateUser = (): UseMutationResult<void, unknown, CreateUserEntry> => {
    return useMutation(createUser);
}

export const useUpdateUser = (): UseMutationResult<void, unknown, UpdateUserEntry> => {
    return useMutation(updateUser);
}