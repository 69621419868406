import { getDoc, getDocs, OrderByDirection, setDoc, updateDoc } from "@firebase/firestore";
import { CreateOrderEntry, UpdateOrderEntry } from "..";
import { FIRESTORE_PATH } from "../../common/enums";
import { firestoreDocWithPathSegments, firestoreQueryCollectionOrderBy, firestoreQueryCollectionOrderByWhere, orderDocs } from "../../firebase";

export const getAllOrders = async () => {
    const snapshot = await orderDocs();

    return snapshot.docs.map((doc) => doc.data());
}

export const getSortedOrdersByUserId = async (sortProperty: string, sortDirection: OrderByDirection, filterProperty: string, userId: string | undefined) => {
    const query = firestoreQueryCollectionOrderByWhere(FIRESTORE_PATH.ORDERS, sortProperty, sortDirection, filterProperty, userId);
    const snapshot = await getDocs(query);

    return snapshot.docs.map((doc) => doc.data());
}


export const getAllOrdersSortedBy = async (property: string, direction: 'asc' | 'desc') => {
    const query = firestoreQueryCollectionOrderBy(FIRESTORE_PATH.ORDERS, property, direction);
    const snapshot = await getDocs(query);

    return snapshot.docs.map((doc) => doc.data());
}

export const getOrderById = async (id: string) => {
    const orderRef = firestoreDocWithPathSegments(FIRESTORE_PATH.ORDERS, id);
    const snapshot = await getDoc(orderRef);
    
    if(snapshot.exists()) {
        return snapshot.data();
    } else {
        console.error(`No document with id: ${id} found`);
    }
}

export const createOrder = async ({ id, data }: CreateOrderEntry) => {
    await setDoc(firestoreDocWithPathSegments(FIRESTORE_PATH.ORDERS, id), data);
}

export const updateOrder = async ({ id, data }: UpdateOrderEntry) => {
    const orderRef = firestoreDocWithPathSegments(FIRESTORE_PATH.ORDERS, id);
    await updateDoc(orderRef, data);
}
